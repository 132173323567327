import React, { useState } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';
import Checkbox from 'lib/Checkbox';
import InfoText from 'lib/InfoText';
import theme from 'config/theme';
import routes from 'config/routes';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import enquiryReceivedImageUrl from 'assets/images/add-digs/enquiryrecieved.svg';
import personDeskImageUrl from 'assets/images/add-digs/personatdesk.svg';
import personDocumentImageUrl from 'assets/images/add-digs/personwithdocument.svg';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { ReactComponent as CameraSvg } from 'assets/images/add-digs/camera.svg';
import { ReactComponent as ClockSvg } from 'assets/images/add-digs/clock.svg';
import { ReactComponent as GroupSvg } from 'assets/images/add-digs/group.svg';
import { ReactComponent as MessageSvg } from 'assets/images/add-digs/message.svg';
import { ReactComponent as CurrencySvg } from 'assets/images/add-digs/currency.svg';

const { colors, fontWeights } = theme;

const UpdatedPrivacyContainer = styled.div`
	display: flex;
	padding: 10px;
`;

const PrivacyContainer = styled.div`
	display: flex;
	padding-bottom: 32px;
`;

const StyledLink = styled(Link)`
	text-decoration: underline;
	color: ${colors.darkBlue};
`;

const CheckboxText = styled(InfoText)`
	color: ${colors.darkBlue};
	margin: 0;
	font-size: 16px;
`;

const ContentContainer = styled.div`
	margin-top: 16px;
`;

const ContentCard = styled.div`
	padding: 20px 24px;
	border-radius: 16px;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
	margin-bottom: 24px;
`;

const ContentCardHeader = styled.div`
	display: flex;
	align-items: center;
`;

const ContentCardHeaderCount = styled.div`
	width: 32px;
	height: 32px;
	min-width: 32px;
	border-radius: 32px;
	border: 1.5px solid ${colors.pink};
	color: ${colors.pink};
	margin-right: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: 600;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		margin-right: 8px;
	}
`;

const ContentCardText = styled(InfoText)`
	flex: 1;
	color: ${colors.darkBlue};
	margin: 0;
	font-size: 16px;
`;

const ContentCardSmallText = styled(ContentCardText)`
	flex: 1;
	color: ${colors.darkBlue};
	margin: 0;
	font-size: 14px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		font-size: 12px;
	}
`;

const ContentCardToggle = styled(InfoText)`
	border-bottom: 2px solid ${colors.darkBlue};
	color: ${colors.darkBlue};
	font-weight: 600;
	margin: 0;
	margin-left: 8px;
	cursor: pointer;
`;

const ContentCardBody = styled.div`
	display: flex;
	align-items: flex-start;
	border-top: 1px solid ${colors.grey10};
	margin-top: 8px;
	padding-top: 16px;
`;

const ContentCardImage = styled.img`
	background: ${colors.grey20};
	min-width: 96px;
	width: 96px;
	height: 96px;
	margin-right: 16px;
`;

const ReamMoreText = styled(Link)`
	color: ${colors.linkBlue};
	text-decoration: underline;
	font-weight: ${fontWeights.bold};
`;
const UpdatedDisclaimerText = styled.div`
	line-height: 21px;
	color: ${colors.darkBlue};
	font-weight: ${fontWeights.normal};
`;
const DisclaimerText = styled.div`
	margin-top: 16px;
	color: ${colors.darkBlue};
	font-weight: 600;
`;

const UpdatedAccentText = styled.span`
	color: ${colors.pink};
	font-weight: ${fontWeights.bold};
`;
const AccentText = styled.span`
	color: ${colors.pink};
`;

const DescriptionText = styled(InfoText)`
	font-size: 16px;
`;

const InfoBoxContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: flex-start;
`;
const InfoBox = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`;
const InfoBoxText = styled.div``;

const InfoContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		gap: 16px;
	}
`;

const HowToContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	align-items: flex-start;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		gap: 24px;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: flex-start;
`;

const LegalContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: flex-start;
`;

const Divider = styled.div`
	border-bottom: 1px solid ${colors.grey10};
	height: 0px;
	width: 100%;
`;

const infoItem: { image: React.FunctionComponent; text: string }[] = [
	{
		image: CameraSvg,
		text: 'At least 1 photo of each room.',
	},
	{
		image: MessageSvg,
		text: 'A short description about your listing.',
	},
	{
		image: CameraSvg,
		text: 'At least 5 photos of the interior and exterior.',
	},
	{
		image: CurrencySvg,
		text: 'Pricing for your listing or it’s individual rooms.',
	},
];

const contentItems: { title: string; imageUrl: string; description: JSX.Element }[] = [
	{
		title: 'Adding your property listing',
		imageUrl: personDeskImageUrl,
		description: (
			<>
				Follow the steps to create your DigsConnect listing. You’ll need to add information about
				the property, the rooms and some photographs. Our support team moderates all listings, to
				ensure listing quality and help you find your next tenant in record breaking time.
			</>
		),
	},
	{
		title: 'Receive booking enquiry messages',
		imageUrl: enquiryReceivedImageUrl,
		description: (
			<>
				Once your listing is live, potential tenants will send you booking enquiry messages, You’ll
				be notified via email and SMS. Reply to enquiries in your inbox. Enquires will expire after
				48 hours if you don't respond.
			</>
		),
	},
	{
		title: 'Finalise the bookings',
		imageUrl: personDocumentImageUrl,
		description: (
			<>
				Chat to the potential tenant on DigsConnect to sort out the details of a lease, arrange
				viewings, etc. Once it’s confirmed that a tenant is going to move-in, we’ll invoice you for
				our fee of 3.5% (exclusive of VAT) of the lease value. You can read more about our pricing
				on: <StyledLink to={routes.landlord_resources}>Landlord Resources</StyledLink>
			</>
		),
	},
];

interface HowToProps {
	termsAccepted: boolean;
	onTermsCheckboxClick(): void;
}

const HowTo = ({ termsAccepted, onTermsCheckboxClick }: HowToProps) => {
	const [expandedItems, setExpandedItems] = useState<{ [key: number]: boolean }>({});

	const handleCardToggle = (index: number) => {
		setExpandedItems(prev => ({
			...prev,
			[index]: !prev[index],
		}));
	};

	return (
		<>
			{featureFlagContainer.isEnabled(FeatureFlag.ManageListingEnhancements) ? (
				<>
					<HowToContainer>
						<TitleContainer>
							<Title noMargin bold largeFont>
								Getting started
							</Title>
							<SubTitle noMargin>
								Before we begin to create your listing, here are some things you may want on hand to
								speed up the process.
							</SubTitle>
						</TitleContainer>
						<InfoContentContainer>
							<InfoBoxContainer>
								{infoItem.map((item, index) => (
									<InfoBox key={item.text}>
										<item.image></item.image>
										<p>{item.text}</p>
									</InfoBox>
								))}
							</InfoBoxContainer>
							<Divider />
							<LegalContainer>
								<UpdatedPrivacyContainer>
									<Checkbox onClick={onTermsCheckboxClick} checked={termsAccepted}>
										<CheckboxText>
											I have read and agree to the DigsConnect{' '}
											<StyledLink to={routes.terms_and_conditions}>terms and conditions</StyledLink>{' '}
											and <StyledLink to={routes.privacy}>privacy policy</StyledLink>
										</CheckboxText>
									</Checkbox>
								</UpdatedPrivacyContainer>
								<UpdatedDisclaimerText>
									In order for DigsConnect to store your information, we require your consent.
									Please accept the terms and conditions above. <br />
									<br />
									Upon confirmation of tenancy the landlord will be charged a fixed finder's fee of
									R800 for leases shorter than 6 months, and R1550 for leases of 6 months or longer
									per tenant. Rates are exclusive of VAT.
									<ReamMoreText to={routes.pricing}>Read more</ReamMoreText>
								</UpdatedDisclaimerText>
							</LegalContainer>
						</InfoContentContainer>
					</HowToContainer>
				</>
			) : (
				<>
					<Title standardMargin>How to get successful bookings</Title>
					<SubTitle>Here’s how it works:</SubTitle>
					<ContentContainer>
						{contentItems.map((item, index) => (
							<ContentCard key={item.title}>
								<ContentCardHeader>
									<ContentCardHeaderCount>{index + 1}</ContentCardHeaderCount>
									<ContentCardText>{item.title}</ContentCardText>
									<ContentCardToggle onClick={() => handleCardToggle(index)}>
										{expandedItems[index] ? 'Close' : 'Learn more'}
									</ContentCardToggle>
								</ContentCardHeader>
								{expandedItems[index] && (
									<ContentCardBody>
										<ContentCardImage src={item.imageUrl} />
										<ContentCardSmallText>{item.description}</ContentCardSmallText>
									</ContentCardBody>
								)}
							</ContentCard>
						))}
					</ContentContainer>
					<Divider />
					<DescriptionText>
						In line with POPIA and GDPR, in order for DigsConnect to store your information, we
						require your consent. Please accept the terms and conditions below. <br />
						<DisclaimerText>
							Upon confirmation of tenancy the landlord will be charged a fixed finder's fee of R800
							for leases shorter than 6 months, and R1550 for leases of 6 months or longer per
							tenant. Rates are exclusive of VAT.
						</DisclaimerText>
					</DescriptionText>
					<PrivacyContainer>
						<Checkbox onClick={onTermsCheckboxClick} checked={termsAccepted}>
							<CheckboxText>
								I have read and agree to the DigsConnect{' '}
								<StyledLink to={routes.terms_and_conditions}>terms and conditions</StyledLink> and{' '}
								<StyledLink to={routes.privacy}>privacy policy</StyledLink>
							</CheckboxText>
						</Checkbox>
					</PrivacyContainer>
				</>
			)}
		</>
	);
};

export default HowTo;
