import React from 'react';

import styled from 'styled-components';

import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';
import theme from 'config/theme';
import { ReactComponent as ResSvg } from 'assets/images/add-digs/student-res.svg';
import { ReactComponent as HouseSvg } from 'assets/images/add-digs/house.svg';
import { ReactComponent as CottageSvg } from 'assets/images/add-digs/cottage.svg';
import { ReactComponent as ApartmentSvg } from 'assets/images/add-digs/apartment.svg';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

import { PropertyType as PropertyTypeEnum } from './types';

const { colors } = theme;

const cardSize = 180;
const cardSizeMobile = 126;
const cardMargin = 8;

const SubHeading = styled(SubTitle)`
	color: ${colors.grey60};
`;

const Card = styled.div<{ selected?: boolean }>`
	border: 2px solid ${colors.darkBlue};
	padding: 16px;
	border-radius: 16px;
	width: ${cardSize}px;
	height: ${cardSize}px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: ${cardMargin}px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		width: ${cardSizeMobile}px;
		height: ${cardSizeMobile}px;
	}

	${({ selected }) =>
		selected
			? `
    color: ${colors.white};
    border-color: ${colors.darkTurquoise};
    background: ${colors.darkTurquoise};
    font-weight: 600;
  `
			: ''}
`;

const CardsContainer = styled.div`
	display: flex;
	justify-content: center;
	max-width: ${cardSize * 2 + cardMargin * 4}px;
	flex-wrap: wrap;
	margin: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		max-width: ${cardSizeMobile * 2 + cardMargin * 4}px;
	}
`;

const CardLabel = styled(SubTitle)<{ selected?: boolean }>`
	margin-top: 16px;
	margin-bottom: 0;
	text-align: center;
	${({ selected }) => (selected ? 'font-weight: 600;' : '')};
`;

const getIconFill = (isSelected: boolean): string => {
	if (isSelected) return colors.white;
	return colors.darkBlue;
};

interface PropertyTypeProps {
	type?: PropertyTypeEnum;
	onTypeSelect(type: PropertyTypeEnum): void;
}

const PropertyType = ({ type, onTypeSelect }: PropertyTypeProps) => {
	const typeItems: { label: string; value: PropertyTypeEnum; icon: JSX.Element }[] = [
		{
			label: 'House',
			value: PropertyTypeEnum.House,
			icon: <HouseSvg fill={getIconFill(PropertyTypeEnum.House === type)} />,
		},
		{
			label: 'Apartment',
			value: PropertyTypeEnum.Apartment,
			icon: <ApartmentSvg fill={getIconFill(PropertyTypeEnum.Apartment === type)} />,
		},
		{
			label: 'Flatlet',
			value: PropertyTypeEnum.Flatlet,
			icon: <CottageSvg fill={getIconFill(PropertyTypeEnum.Flatlet === type)} />,
		},
		{
			label: 'Student Residence',
			value: PropertyTypeEnum.StudentResidence,
			icon: <ResSvg fill={getIconFill(PropertyTypeEnum.StudentResidence === type)} />,
		},
	];

	return (
		<>
			<Title standardMargin>
				What type of property best describes where you are listing your available rooms?
			</Title>
			<SubHeading>I am listing a...</SubHeading>
			<CardsContainer>
				{typeItems.map(typeItem => (
					<Card
						selected={typeItem.value === type}
						key={typeItem.value}
						onClick={() => onTypeSelect(typeItem.value)}
					>
						{typeItem.icon}
						<CardLabel selected={typeItem.value === type}>{typeItem.label}</CardLabel>
					</Card>
				))}
			</CardsContainer>
		</>
	);
};

export default PropertyType;
