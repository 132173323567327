import React from 'react';

import styled from 'styled-components';

import Title from 'lib/Title';
import theme from 'config/theme';
import SubTitle from 'lib/SubTitle';
import { ReactComponent as ResSvg } from 'assets/images/add-digs/icon_res.svg';
import { ReactComponent as ApartmentSvg } from 'assets/images/add-digs/icon_apartment.svg';
import { ReactComponent as HouseSvg } from 'assets/images/add-digs/icon_house.svg';
import { ReactComponent as CottageSvg } from 'assets/images/add-digs/icon_cottage.svg';
import { ReactComponent as EmailIcon } from 'assets/images/digs-listing/email.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/digs-listing/facebook.svg';
import { ReactComponent as LinkIcon } from 'assets/images/digs-listing/link.svg';
import { Property } from 'models/property';
import { PropertyType } from './types';
import Button from 'lib/Button';
import routes from 'config/routes';
import shareService from 'services/shareService';
import { notifySuccess } from 'lib/Notifications';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const { colors } = theme;

const typeIconSize = 200;
const typeIconStyleMobile = {
	minWidth: 100,
	height: 100,
	width: 100,
	minHeight: 100,
	marginBottom: 16,
};

const shareIconStyle = {
	marginLeft: 16,
	height: 26,
};

interface SuccessProps {
	property: Property;
	moderated: boolean;
}

const SubTitleMessage = styled(SubTitle)`
	color: ${colors.darkBlue};
`;

const Success = ({ property, moderated }: SuccessProps) => {
	const width = useScreenWidth();

	const isMobile = width < BREAKPOINT_TABLET_PX;

	const listingDetailPath = property.digs_search_listing_uuid
		? `/digs/view/${property.digs_search_listing_uuid}`
		: '';

	const handleCopyToClipboardClick = (listingDetailPath: string) => {
		shareService.copyToClipboard(`${window.location.origin}${listingDetailPath}`);
		notifySuccess('Link copied');
	};

	return (
		<Container>
			{!isMobile && (
				<LeftColumn>
					{property.sub_type === PropertyType.StudentResidence && (
						<ResSvg height={typeIconSize} width={typeIconSize} />
					)}
					{property.sub_type === PropertyType.Apartment && (
						<ApartmentSvg height={typeIconSize} width={typeIconSize} />
					)}
					{property.sub_type === PropertyType.House && (
						<HouseSvg height={typeIconSize} width={typeIconSize} />
					)}
					{property.sub_type === PropertyType.Flatlet && (
						<CottageSvg height={typeIconSize} width={typeIconSize} />
					)}
				</LeftColumn>
			)}
			<Column>
				<PageContentContainer>
					{isMobile && (
						<>
							{property.sub_type === PropertyType.StudentResidence && (
								<ResSvg style={typeIconStyleMobile} />
							)}
							{property.sub_type === PropertyType.Apartment && (
								<ApartmentSvg style={typeIconStyleMobile} />
							)}
							{property.sub_type === PropertyType.House && <HouseSvg style={typeIconStyleMobile} />}
							{property.sub_type === PropertyType.Flatlet && (
								<CottageSvg style={typeIconStyleMobile} />
							)}
						</>
					)}
					<TopSection>
						<Title standardMargin>
							{moderated
								? 'Great news! Your listing has been successfully posted!'
								: 'Great news! Your listing has been successfully submitted into moderation!'}
						</Title>
						{!moderated && (
							<SubTitleMessage>
								First time listings go into moderation. As soon as our support team has moderated
								your listing, it will go live!
							</SubTitleMessage>
						)}
						<SubHeader>
							You can find insights and edit your listings at the ‘manage your listings’, as well as
							update their capacity as you place tenants.
						</SubHeader>
						<Button isPink link={routes.manage_digs}>
							Manage your listings
						</Button>
						<ButtonWrapper>
							<Button
								isOutline
								link={routes.create_listing_edit.replace(':id?', '')}
								onClick={() => {
									window.location.href = routes.create_listing_edit.replace(':id?', '');
								}}
							>
								Create another listing
							</Button>
						</ButtonWrapper>
					</TopSection>
					<ShareBlockContainer>
						{moderated && listingDetailPath && (
							<ShareContainer>
								<ShareListingLabel>Share this listing</ShareListingLabel>
								<ShareIconsContainer>
									<LinkIcon
										onClick={() => handleCopyToClipboardClick(listingDetailPath)}
										style={shareIconStyle}
									/>
									<a
										href={shareService.getEmailShareLink(
											`${window.location.origin}${listingDetailPath}`,
										)}
									>
										<EmailIcon style={shareIconStyle} />
									</a>
									<a
										href={shareService.getFacebookShareLink(listingDetailPath)}
										target="_blank"
										rel="noopener noreferrer"
									>
										<FacebookIcon style={shareIconStyle} />
									</a>
								</ShareIconsContainer>
							</ShareContainer>
						)}
					</ShareBlockContainer>
				</PageContentContainer>
			</Column>
		</Container>
	);
};

export default Success;

const Container = styled.div`
	display: flex;
	height: 100%;
`;

const Column = styled.div`
	width: 50%;
	padding: 24px;
	padding-bottom: 0;
	flex: 1;
  height: 100%;
  overflow-y: auto
`;

const ShareBlockContainer = styled.div`
	padding-bottom: 24px;
	width: 100%;
`;

const LeftColumn = styled(Column)`
	padding-bottom: 24px;
	background: ${colors.darkTurquoiseFaded};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
	margin-bottom: 24px;
`;

const ShareContainer = styled.div`
	background: ${colors.darkTurquoiseFaded};
	border-radius: 16px;
	height: 56px;
	min-height: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px;
	margin-top: 16px;
	width: 100%;
`;

const ShareListingLabel = styled.div`
	color: ${colors.darkBlue};
	font-size: 14px;
	font-weight: 600;
`;

const ShareIconsContainer = styled.div`
	display: flex;
	cursor: pointer;
`;

const PageContentContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 500px;
	margin: auto;
	text-align: center;
`;

const ButtonWrapper = styled.div`
	width: 100%;
	margin-top: 16px;
`;

const TopSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
`;
