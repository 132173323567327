import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import Button from 'lib/Button';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import { ReactComponent as PhoneSvg } from 'assets/images/add-digs/phone.svg';

const { colors, fontWeights } = theme;

const ContactUsContainer = styled.button`
	text-align: center;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: row;
	gap: 10px;
	width: 151px;
	min-height: 56px;
	border-radius: 15px;
	border: 2px solid ${colors.darkBlue};
	background: ${colors.white};
	self-align: flex-end;
	color: ${colors.darkBlue};
	font-weight: ${fontWeights.bold};

	&:hover {
		cursor: pointer;
	}
`;

const ContactUsButton = () => (
	<ContactUsContainer
		onClick={() => {
			modalContainer.set(ModalType.ContactUs);
		}}
	>
		<PhoneSvg />
		Need help?
	</ContactUsContainer>
);

export default ContactUsButton;
