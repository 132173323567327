import React from 'react';

import styled from 'styled-components';

import { BREAKPOINT_DESKTOP_PX, BREAKPOINT_DESKTOP_LARGE_PX } from 'constants/breakPoints';
import theme from 'config/theme';

import { StepInfoContent as StepInfoContentData } from './types';
import StepInfoContent from './StepInfoContent';
import ContactUsContent from './ContactUsContent';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import ContactUsButton from './ContactUsButton';

const { colors, headerHeight } = theme;

const StyledRightPanel = styled.div`
	display: none;
	min-width: 350px;
	overflow-y: auto;
	position: static;

	@media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
		display: inline;
		width: 350px;
	}

	@media (min-width: ${BREAKPOINT_DESKTOP_LARGE_PX}px) {
		width: 450px;
	}
`;

const RightPanelContent = styled.div`
	display: flex;
	flex-direction: column;
	margin: 32px;
	margin-bottom: 0px;
	padding-bottom: 16px;
	align-items: flex-end;
	justify-content: space-between;
	overflow-y: auto;
	height: calc(100% - 32px);
	gap: 10px;
	position: relative;
`;

const InfoBox = styled.div`
	padding: 24px;
	border-radius: 16px;
	border: 1px solid ${colors.grey20};
	margin-bottom: 24px;
`;

interface RightPanelProps {
	content?: StepInfoContentData;
}

const RightPanel = ({ content }: RightPanelProps) => (
	<StyledRightPanel>
		<RightPanelContent>
			{content && (
				<InfoBox>
					<StepInfoContent content={content} />
				</InfoBox>
			)}
			{featureFlagContainer.isEnabled(FeatureFlag.ManageListingEnhancements) && <ContactUsButton />}
		</RightPanelContent>
	</StyledRightPanel>
);

export default RightPanel;
