import React from 'react';

import styled from 'styled-components';

import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';
import theme from 'config/theme';
import { Feature, Property } from 'models/property';
import ListingCardMobile from 'components/digs-search-v2/ListingCardMobile';
import { Listing } from 'models/listing';
import Button from 'lib/Button';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { useScreenWidth } from 'hooks/useScreenWidth';
import userContainer from 'containers/user';

import { CreateListingStep, CreateListingSubStep, LeaseType } from './types';
import InfoBox from './InfoBox';
import routes from 'config/routes';

const { colors } = theme;

const Container = styled.div`
	padding-bottom: 32px;
`;

const ColumnsContainer = styled.div`
	color: ${colors.darkBlue};
	margin-top: 24px;
	margin-bottom: 16px;
	display: flex;
	flex-wrap: wrap;
`;

const Column = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		width: 100%;
	}
`;

const RightColumn = styled(Column)`
	margin-top: 24px;
`;

const NavigateLink = styled.div`
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	margin-bottom: 24px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
`;

const propertyToListing = (property: Property, features: Feature[]): Listing =>
	(({
		uuid: '',
		title: property.title,
		lease_term: property.lease_term,
		lease_term_flexible: property.lease_term_flexible,
		availability_date: property.availability_date,
		photos: property.photos,
		rooms: property.rooms,
		lease_type: property.lease_type,
		location: property.location,
		major_type: property.lease_type,
		sub_type: property.sub_type,
		price: property.price || [...property.rooms].sort((a, b) => a.price - b.price)[0].price,
		display_price_from: property.lease_type === LeaseType.RoomByRoom,
		bedroom_count: property.total_bedrooms,
		university_distances: [],
		currency: property.currency,
		billing_cycle: property.billing_cycle,
		external: false,
		features: features,
		furnished: property.furnished,
		landlord: {
			is_trusted_landlord: userContainer.state.v2.is_trusted_landlord,
		},
	} as unknown) as Listing);

const navigationItems: {
	step: CreateListingStep;
	subStep?: CreateListingSubStep;
	label: string;
}[] = [
	{
		step: CreateListingStep.YourListing,
		subStep: CreateListingSubStep.YourListingRooms,
		label: 'Edit rooms',
	},
	{
		step: CreateListingStep.Features,
		label: 'Edit features',
	},
	{
		step: CreateListingStep.Occupants,
		label: 'Edit occupancy',
	},
	{
		step: CreateListingStep.Location,
		label: 'Edit location',
	},
	{
		step: CreateListingStep.Pictures,
		label: 'Edit pictures',
	},
	{
		step: CreateListingStep.NameListing,
		label: 'Edit description',
	},
	{
		step: CreateListingStep.Pricing,
		label: 'Edit pricing',
	},
];

interface PreviewProps {
	property: Property;
  features: Feature[];
	onStepNavigate(step: CreateListingStep, subStep?: CreateListingSubStep): void;
}

const Preview = ({ property, features, onStepNavigate }: PreviewProps) => {
	const width = useScreenWidth();
	const listing = propertyToListing(property, features);

	const isMobile = width < BREAKPOINT_MOBILE_PX;
	return (
		<>
			<Title standardMargin>Listing Preview</Title>
			<SubHeader>
				This is what you’re new listing will be displayed as to potential tenants. Click ‘Post my
				listing’ below if everything looks in order.
			</SubHeader>
			{isMobile && (
				<InfoBox text="Doesn’t look quite right? You can go back and edit any of the steps below." />
			)}
			<Container>
				<ColumnsContainer>
					<Column>
						<ListingCardMobile
							{...listing}
							onClick={() => {}}
							widthPx={280}
							fixedHeightPx={320}
							hideFavourite
						/>
					</Column>
					<RightColumn>
						{navigationItems.map(item => (
							<NavigateLink
								key={item.label}
								onClick={() => onStepNavigate(item.step, item.subStep)}
							>
								{item.label}
							</NavigateLink>
						))}
						<Button
							noMargin
							maxWidth={200}
							height={40}
							link={routes.digs_detail_preview.replace(':id', property.digs_search_listing_uuid)}
							openLinkInNewTab
						>
							View detailed preview
						</Button>
					</RightColumn>
				</ColumnsContainer>
				{!isMobile && (
					<InfoBox text="Doesn’t look quite right? You can go back and edit any of the steps using the navigation on the left or the shortcut buttons next to the listing preview." />
				)}
			</Container>
		</>
	);
};

export default Preview;
