import React, { createRef, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { match } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { History } from 'history';

import api from 'api';
import { Feature, Photo, Property, Room } from 'models/property';
import { Currency, FurnishedType } from 'models/listing';
import theme from 'config/theme';
import { PageMeta } from 'constants/page_meta';
import {
	BREAKPOINT_MOBILE_PX,
	BREAKPOINT_TABLET_PX,
	BREAKPOINT_DESKTOP_PX,
} from 'constants/breakPoints';
import Header from 'components/common/header';
import withAuth from 'components/common/with-auth';
import ProgressBar from 'lib/ProgressBar';
import SubTitle from 'lib/SubTitle';
import { Cross } from 'lib/Cross';
import { Button } from 'lib/Button';
import { notifyError, notifyFailure } from 'lib/Notifications';
import routes from 'config/routes';
import { ReactComponent as InfoIconSvg } from 'assets/images/icons/info-circle-outline.svg';
import { ReactComponent as QuestionSvg } from 'assets/images/icons/question.svg';
import modalContainer from 'containers/modal';
import userContainer from 'containers/user';
import { ModalType } from 'constants/modalTypes';

import addDigsService, { steps } from './addDigsService';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import {
	CreateListingStep,
	StepStatus,
	CreateListingState,
	CreateListingSubStep,
	PropertyType as PropertyTypeEnum,
	LeaseType,
	RentalPeriod,
} from './types';
import HowTo from './HowTo';
import PropertyType from './PropertyType';
import Availability from './Availability';
import Rooms from './Rooms';
import ListingDetails from './ListingDetails';
import RoomModal from './RoomModal';
import Features from './Features';
import Occupants from './Occupants';
import Rules from './Rules';
import Atmosphere from './Atmosphere';
import Address from './Address';
import PictureUpload from './PictureUpload';
import Video from './Video';
import NameListing from './NameListing';
import Pricing from './Pricing';
import Preview from './Preview';
import Success from './Success';
import StepInfoModal from './StepInfoModal';
import ContactUsModal from './ContactUsModal';
import { userProfileService } from 'services/userProfileService';
import {
	analyticsService,
	CreateListingStepEvent,
	EventName,
	PageName,
} from 'services/analyticsService';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { ReactComponent as PhoneSvg } from 'assets/images/add-digs/phone-mobile.svg';

const meta = PageMeta.getStatic('create_listing');

const { colors, fontWeights, headerHeight, mobileHeaderHeight, zIndices } = theme;

const mobileTopBarHeight = '60px';

const PageContainer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	border-top: 1px solid ${colors.gray};
	overflow-y: auto;
	@media (min-width: ${BREAKPOINT_MOBILE_PX}px) {
		overflow-y: visible;
		top: ${mobileHeaderHeight};
	}
	@media (min-width: ${BREAKPOINT_TABLET_PX + 1}px) {
		top: ${headerHeight};
	}
`;

// Need this as we don't want the header to show on mobile but can't use display: none; or opacity: 0; else the modals won't display
const HeaderContainer = styled.div`
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		header {
			margin-top: -100px;
		}
	}
`;

const PanelsContainer = styled.div`
	display: flex;
	height: 100%;
`;

const CenterPanel = styled.div`
	margin: auto;
	width: 55%;
	flex: 1;
	border-left: 1px solid ${colors.gray};
	border-right: 1px solid ${colors.gray};
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;

const CenterPanelTopBar = styled.div`
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		height: ${mobileTopBarHeight};
		min-height: ${mobileTopBarHeight};
		width: 100%;
		display: flex;
		justify-content: space-between;
		overflow-y: auto;
		align-items: center;
		padding: 0 16px;
		transition: all 0.2s;
	}
`;

const CenterPanelContentContainer = styled.div`
	display: flex;
	position: sticky;
	width: 100%;
	margin: 32px auto;
	margin-bottom: 0;
	padding: 0 16px;
	overflow-y: auto;
	flex: 1;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		overflow-y: visible;
		margin-bottom: 72px;
	}
	@media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
		padding: 0 40px;
	}
`;

const CenterPanelContent = styled.div`
	margin: 0 auto;
	width: 100%;
`;

const SaveAndExitText = styled.div`
	font-size: 14px;
	font-weight: ${fontWeights.bold};
	color: ${colors.linkBlue};
`;

const BackButton = styled(SubTitle)<{ isDisabled?: boolean }>`
	margin: 0;
	width: 100px;
	height: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-tap-highlight-color: transparent;
	opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
	cursor: pointer;
`;

const ActionBar = styled.div<{ greyBackground?: boolean; singleItem?: boolean }>`
	display: flex;
	width: 100%;
	justify-content: ${({ singleItem }) => (singleItem ? 'flex-end' : 'space-between')};
	padding: 8px 16px;
	border-top: 1px solid ${colors.gray};
	background: ${({ greyBackground }) => (greyBackground ? colors.gray : colors.white)};

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		padding: 16px 24px;
	}
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
	}
`;

const MobileOnly = styled.div`
	display: block;
	@media (min-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: none;
	}
`;

const DesktopOnly = styled.div`
	display: none;
	@media (min-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: block;
	}
`;

const MobileHeader = styled.div`
	position: fixed;
	top: 0;
	width: 100vw;
	background-color: ${colors.background};
	z-index: ${zIndices.footer};
`;

const MobileHeaderSpacing = styled.div`
	height: ${mobileTopBarHeight};
`;

const InfoButton = styled.button`
	cursor: pointer;
	border: none;
	border-radius: 12px;
	background: ${colors.grey05};
	color: ${colors.darkBlue};
	font: inherit;
	font-size: 14px;
	font-weight: ${fontWeights.bold};
	padding: 12px 16px;
	display: flex;
	align-items: center;
	gap: 10px;
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding: 4px 10px 5px;
		justify-content: flex-end;
	}
`;

const QuestionIcon = styled(QuestionSvg)`
	fill: ${colors.pink};
	width: 20px;
	height: 20px;
	cursor: pointer;
	margin-right: 16px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	justify-content: flex-end;
`;

const InfoIcon = styled(InfoIconSvg)`
	width: 16px;
	height: 16px;
	margin-right: 8px;
`;

const defaultCurrency = { currency_symbol: 'R', currency_code: 'ZAR' } as Currency;
const stepDisplayText = {
	[CreateListingStep.Unknown]: '',
	[CreateListingStep.HowTo]: featureFlagContainer.isEnabled(FeatureFlag.ManageListingEnhancements)
		? 'Getting started'
		: 'How to get successful bookings',
	[CreateListingStep.YourListing]: 'Your listing',
	[CreateListingStep.Features]: 'Features',
	[CreateListingStep.Occupants]: 'Occupants',
	[CreateListingStep.Location]: 'Location',
	[CreateListingStep.Pictures]: 'Pictures & video',
	[CreateListingStep.NameListing]: 'Name your listing',
	[CreateListingStep.Pricing]: 'Set your pricing',
	[CreateListingStep.Preview]: 'Listing preview',
};

const subStepDisplayText = {
	[CreateListingSubStep.Unknown]: '',
	[CreateListingSubStep.YourListingPropertyType]: 'Type of property',
	[CreateListingSubStep.YourListingAvailability]: 'Availability',
	[CreateListingSubStep.YourListingRooms]: 'Rooms',
	[CreateListingSubStep.YourListingDetails]: 'Listing details',
	[CreateListingSubStep.OccupantsCapacity]: 'Capacity',
	[CreateListingSubStep.OccupantsRules]: 'Rules',
	[CreateListingSubStep.LocationAtmosphere]: 'Atmosphere',
	[CreateListingSubStep.LocationAddress]: 'Address',
	[CreateListingSubStep.PicturesUpload]: 'Picture upload',
	[CreateListingSubStep.PicturesVideo]: 'Walk-through video',
};

const calculateProgressPercentage = (
	step: CreateListingStep,
	steps: CreateListingStep[],
): number => {
	const current = steps.indexOf(step) + 1;
	return Math.round((current / steps.length) * 100);
};

const getStepEvent = (currentStep: StepInfo): CreateListingStepEvent => {
	if (currentStep.subStep) {
		switch (currentStep.subStep) {
			case CreateListingSubStep.YourListingPropertyType:
				return CreateListingStepEvent.YourListingPropertyType;
			case CreateListingSubStep.YourListingAvailability:
				return CreateListingStepEvent.YourListingAvailability;
			case CreateListingSubStep.YourListingRooms:
				return CreateListingStepEvent.YourListingRooms;
			case CreateListingSubStep.YourListingDetails:
				return CreateListingStepEvent.YourListingDetails;
			case CreateListingSubStep.OccupantsCapacity:
				return CreateListingStepEvent.OccupantsCapacity;
			case CreateListingSubStep.OccupantsRules:
				return CreateListingStepEvent.OccupantsRules;
			case CreateListingSubStep.LocationAtmosphere:
				return CreateListingStepEvent.LocationAtmosphere;
			case CreateListingSubStep.LocationAddress:
				return CreateListingStepEvent.LocationAddress;
			case CreateListingSubStep.PicturesUpload:
				return CreateListingStepEvent.PicturesUpload;
			case CreateListingSubStep.PicturesVideo:
				return CreateListingStepEvent.PicturesVideo;
		}
	}
	switch (currentStep.step) {
		case CreateListingStep.HowTo:
			return CreateListingStepEvent.HowTo;
		case CreateListingStep.Features:
			return CreateListingStepEvent.Features;
		case CreateListingStep.NameListing:
			return CreateListingStepEvent.NameListing;
		case CreateListingStep.Pricing:
			return CreateListingStepEvent.Pricing;
		case CreateListingStep.Preview:
			return CreateListingStepEvent.Preview;
	}
	return CreateListingStepEvent.Unknown;
};

const initialState: CreateListingState = {
	termsAccepted: false,
	leaseType: LeaseType.Unknown,
	propertyType: PropertyTypeEnum.Unknown,
	singleRoomCount: 0,
	roomTypeCount: 0,
	sharedRoomCount: 0,
	bedroomCount: 0,
	sharedBathrooms: 0,
	tenantsPerSharedBathroom: 0,
	m2: 0,
	furnished: FurnishedType.Unknown,
	availabilityDate: '',
	flexibleMoveInDate: false,
	leasePeriodLength: 0,
	flexibleLeasePeriod: false,
	currentOccupants: 0,
	availablePlaces: 0,
	totalOccupants: 0,
	currentTenantGender: null,
	preferredNewTenantGender: null,
	rooms: [],
	address: null,
	photos: [],
	videoLink: '',
	title: '',
	description: '',
	rentalPeriod: RentalPeriod.Monthly,
	price: 0,
};

interface StepInfo {
	step: CreateListingStep;
	subStep?: CreateListingSubStep;
}

interface AddDigsProps {
	history: History;
	match: match<{
		id?: string;
	}>;
}

const AddDigs = ({ history, match }: AddDigsProps) => {
	const [currentStep, setCurrentStep] = useState<StepInfo>({
		step: CreateListingStep.Unknown,
		subStep: CreateListingSubStep.Unknown,
	});
	const [progressPercentage, setProgressPercentage] = useState<number>(0);
	const [termsAccepted, setTermsAccepted] = useState<boolean>(initialState.termsAccepted);
	const [propertyType, setPropertyType] = useState<PropertyTypeEnum | undefined>(
		initialState.propertyType,
	);
	const [leaseType, setLeaseType] = useState<LeaseType>(initialState.leaseType);
	const [singleRoomCount, setSingleRoomCount] = useState<number>(initialState.singleRoomCount);
	const [sharedRoomCount, setSharedRoomCount] = useState<number>(initialState.sharedRoomCount);
	const [roomTypeCount, setRoomTypeCount] = useState<number>(initialState.roomTypeCount);
	const [bedroomCount, setBedroomCount] = useState<number>(initialState.bedroomCount);
	const [savedState, setSavedState] = useState<CreateListingState>(initialState);
	const [stepStatuses, setStepStatuses] = useState<{ [step: number]: StepStatus }>({});
	const [subStepStatuses, setSubStepStatuses] = useState<{ [subStep: number]: StepStatus }>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [property, setProperty] = useState<Property>();
	const [propertyFeatures, setPropertyFeatures] = useState<Feature[]>([]);
	const [selectedRoom, setSelectedRoom] = useState<Room>();
	const [sharedBathrooms, setSharedBathrooms] = useState(initialState.sharedBathrooms);
	const [tenantsPerSharedBathroom, setTenantsPerSharedBathroom] = useState(
		initialState.tenantsPerSharedBathroom,
	);
	const [m2, setM2] = useState(initialState.m2);
	const [furnished, setFurnished] = useState(initialState.furnished);
	const [availabilityDate, setAvailabilityDate] = useState(initialState.availabilityDate);
	const [flexibleMoveInDate, setFlexibleMoveInDate] = useState(initialState.flexibleMoveInDate);
	const [leasePeriodLength, setLeasePeriodLength] = useState(initialState.leasePeriodLength);
	const [flexibleLeasePeriod, setFlexibleLeasePeriod] = useState(initialState.flexibleLeasePeriod);
	const [currentOccupants, setCurrentOccupants] = useState(initialState.currentOccupants);
	const [availablePlaces, setAvailablePlaces] = useState(initialState.availablePlaces);
	const [totalOccupants, setTotalOccupants] = useState(initialState.totalOccupants);
	const [currentTenantGender, setCurrentTenantGender] = useState(initialState.currentTenantGender);
	const [preferredNewTenantGender, setPreferredNewTenantGender] = useState(
		initialState.preferredNewTenantGender,
	);
	const [, setAddress] = useState(initialState.address);
	const [videoLink, setVideoLink] = useState(initialState.videoLink);
	const [title, setTitle] = useState(initialState.title);
	const [description, setDescription] = useState(initialState.description);
	const [rentalPeriod, setRentalPeriod] = useState<RentalPeriod>(initialState.rentalPeriod);
	const [price, setPrice] = useState<number>(initialState.price);
	const [photoTags, setPhotoTags] = useState<string[]>([]);
	const [showSuccess, setShowSuccess] = useState(false);
	const [moderated, setModerated] = useState(false);
	const [showStepInfoModal, setShowStepInfoModal] = useState(false);
	const [showContactUsModal, setShowContactUsModal] = useState(false);
	const [connectionsCount, setConnectionsCount] = useState(0);
	const scrollTopPanelRef = useRef<HTMLDivElement>(null);
	const scrollTopContainerRef = useRef<HTMLDivElement>(null);
	const isLastStep = currentStep.step === steps[steps.length - 1];
	const stepInfoContent = addDigsService.getStepInfoContent(
		currentStep.step,
		currentStep.subStep || CreateListingSubStep.Unknown,
		savedState,
		connectionsCount,
	);

	useEffect(() => {
		analyticsService.trackPage(PageName.CreateListing);
		api.bookings
			.getConnectionCounter()
			.then(({ data }) => setConnectionsCount(data.total_count))
			.catch(() => {});
	}, []);

	useEffect(() => {
		const propertyId = match.params.id;
		if (!propertyId) {
			setCurrentStep({
				step: CreateListingStep.HowTo,
				subStep: CreateListingSubStep.Unknown,
			});
			return;
		}
		api.property.get(propertyId).then(response => {
			setProperty(response.data);
			const state = addDigsService.propertyToState(response.data);
			const firstInvalidStep = steps.find(step => !addDigsService.validateStep(step, state).valid);
			if (!firstInvalidStep) {
				setCurrentStep({ step: CreateListingStep.Preview });
				return;
			}
			const subSteps = addDigsService.getSubSteps(firstInvalidStep);
			const firstInvalidSubStep = (subSteps || []).find(
				subStep => !addDigsService.validateSubStep(subStep, state).valid,
			);

			setCurrentStep({
				step: firstInvalidStep,
				subStep: firstInvalidSubStep || CreateListingSubStep.Unknown,
			});
		});
		api.property.getPropertyFeatures(propertyId).then(response => {
			setPropertyFeatures(response.data);
		});
	}, [match.params.id]);

	useEffect(() => {
		if (!currentStep.step) {
			return;
		}
		setProgressPercentage(calculateProgressPercentage(currentStep.step, steps));
		const event = getStepEvent(currentStep);
		analyticsService.trackEvent(EventName.CreateListingEvent, {
			create_listing_step: event,
		});
	}, [currentStep]);

	useEffect(() => {
		setStepStatuses(addDigsService.getStepStatuses(steps, savedState));
		setSubStepStatuses(addDigsService.getSubStepStatuses(steps, savedState));
		setTermsAccepted(savedState.termsAccepted);
		setPropertyType(savedState.propertyType);
		setLeaseType(savedState.leaseType);
		setSingleRoomCount(savedState.singleRoomCount);
		setSharedRoomCount(savedState.sharedRoomCount);
		setRoomTypeCount(savedState.roomTypeCount);
		setBedroomCount(savedState.bedroomCount);
		setSharedBathrooms(savedState.sharedBathrooms);
		setTenantsPerSharedBathroom(savedState.tenantsPerSharedBathroom);
		setM2(savedState.m2);
		setFurnished(savedState.furnished);
		setAvailabilityDate(savedState.availabilityDate);
		setFlexibleMoveInDate(savedState.flexibleMoveInDate);
		setLeasePeriodLength(savedState.leasePeriodLength);
		setFlexibleLeasePeriod(savedState.flexibleLeasePeriod);
		setCurrentOccupants(savedState.currentOccupants);
		setAvailablePlaces(savedState.availablePlaces);
		setTotalOccupants(savedState.totalOccupants);
		setCurrentTenantGender(savedState.currentTenantGender);
		setPreferredNewTenantGender(savedState.preferredNewTenantGender);
		setAddress(savedState.address);
		setVideoLink(savedState.videoLink);
		setTitle(savedState.title);
		setDescription(savedState.description);
		setRentalPeriod(savedState.rentalPeriod);
		setPrice(savedState.price);
	}, [savedState]);

	useEffect(() => {
		if (!property) {
			return;
		}
		setSavedState(addDigsService.propertyToState(property));
		api.property.getPhotoTags().then(response => {
			setPhotoTags(response.data.tags);
		});
	}, [property]);

	useEffect(() => {
		if (!property) {
			return;
		}
		if (!property.sub_type) {
			setTitle('');
			return;
		}
		if (title) {
			return;
		}
		if (!property.location || (!property.location.suburb && !property.location.city)) {
			return;
		}

		let defaultTitle = `${property.sub_type}`;
		if (property.location && (property.location.suburb || property.location.city)) {
			defaultTitle += ` in ${property.location.suburb || property.location.city}`;
		}
		if (property.lease_type === LeaseType.RoomByRoom) {
			defaultTitle = `${property.total_bedrooms} bedroom ${defaultTitle}`;
		}
		if (defaultTitle.startsWith(property.sub_type || '')) {
			defaultTitle = `${defaultTitle[0].toUpperCase()}${defaultTitle.slice(1)}`;
		}
		setTitle(defaultTitle);
	}, [title, property]);

	const scrollToTop = () => {
		if (scrollTopPanelRef.current) {
			scrollTopPanelRef.current.scrollTop = 0;
		}

		if (scrollTopContainerRef.current) {
			scrollTopContainerRef.current.scrollTop = 0;
		}
	};

	const handleTermsCheckboxClick = () => {
		setTermsAccepted(prev => !prev);
	};

	const handleRoomSelect = (room: Room) => {
		setSelectedRoom(room);
	};

	const handleRoomUpdated = (updatedRoom: Room) => {
		setProperty(
			prev =>
				prev && {
					...prev,
					rooms: prev.rooms.map(room =>
						room.uuid === updatedRoom.uuid ? { ...updatedRoom } : room,
					),
				},
		);
	};

	const handleRoomPhotosUpdate = (photos: Photo[]) => {
		if (!selectedRoom) {
			return;
		}
		setProperty(
			prev =>
				prev && {
					...prev,
					rooms: prev.rooms.map(room =>
						room.uuid === selectedRoom.uuid ? { ...room, photos } : room,
					),
				},
		);
		setSelectedRoom(
			prev =>
				prev && {
					...prev,
					photos,
				},
		);
	};

	const handleFeatureToggle = (feature: Feature) => {
		setPropertyFeatures(prev =>
			prev.map(f => ({ ...f, active: f.id === feature.id ? !f.active : f.active })),
		);
	};

	const handleAddressChange = async (address: any) => {
		setAddress(address);
		if (!property || isLoading) {
			return;
		}

		// Save address immediately in order to calculate coordinates on the backend
		setIsLoading(true);
		const response = await api.property.update({
			...property,
			location_data: address,
		});

		if (response.status !== 200) {
			setIsLoading(false);
			notifyError('Something went wrong, please try again later');
			return;
		}

		setIsLoading(false);
		setProperty(response.data);
	};

	const handlePhotoUpload = async (data: FormData) => {
		if (isLoading) {
			return;
		}
		if (!property || !property.uuid) {
			return;
		}

		setIsLoading(true);
		const response = await api.property.uploadPhoto(property.uuid, data);

		if (response.status !== 201) {
			notifyFailure('Failed to upload photo');
			return;
		}

		const photosResponse = await api.property.getPhotos(property.uuid);

		if (photosResponse.status !== 200) {
			notifyFailure('Unexpected error occurred');
			return;
		}
		setIsLoading(false);

		setProperty(
			prev =>
				prev && {
					...prev,
					photos: photosResponse.data,
				},
		);
	};

	const handleMultiplePhotoUpload = async (photosData: FormData[]) => {
		if (isLoading) {
			return;
		}
		if (!property || !property.uuid) {
			return;
		}

		setIsLoading(true);
		const responses = await Promise.all(
			photosData.map(async data => await api.property.uploadPhoto(property?.uuid || '', data)),
		);

		responses.forEach(async response => {
			if (response.status !== 201) {
				notifyFailure('Failed to upload photo');
				return;
			}
		});

		const photosResponse = await api.property.getPhotos(property?.uuid || '');

		setIsLoading(false);

		if (photosResponse.status !== 200) {
			notifyFailure('Unexpected error occurred');
			return;
		}

		setProperty(
			prev =>
				prev && {
					...prev,
					photos: photosResponse.data,
				},
		);
	};

	const handlePhotoDelete = async (id: string) => {
		if (isLoading) {
			return;
		}
		if (!property || !property.uuid) {
			return;
		}

		setIsLoading(true);

		const response = await api.property.deletePhoto(property.uuid, id);

		setIsLoading(false);

		if (response.status !== 204) {
			notifyFailure('Failed to delete photo');
			return;
		}

		const newPhotos = property.photos.filter(photo => photo.uuid !== id);
		setProperty(
			prev =>
				prev && {
					...prev,
					photos: newPhotos,
				},
		);
	};

	const handlePhotoUpdate = (photo: Photo) => {
		if (!property || !property.uuid) {
			return;
		}

		const newPhotos = property.photos.map(p => (p.uuid === photo.uuid ? photo : p));
		setProperty(
			prev =>
				prev && {
					...prev,
					photos: newPhotos,
				},
		);
	};

	const handlePropertyTypeChange = (type: PropertyTypeEnum) => {
		setPropertyType(type);
		if (!property) {
			return;
		}
		setLeaseType(
			type === PropertyTypeEnum.StudentResidence ? LeaseType.RoomByRoom : savedState.leaseType,
		);
	};

	const handlePhotosReorder = async (photos: Photo[]) => {
		if (isLoading) {
			return;
		}
		if (!property || !property.uuid) {
			return;
		}

		setIsLoading(true);

		const response = await api.property.updatePhotoOrdinals(property.uuid, photos);

		setIsLoading(false);

		if (response.status !== 200) {
			notifyFailure('Failed to update ordinals');
			return;
		}

		setProperty(
			prev =>
				prev && {
					...prev,
					photos,
				},
		);
	};

	const handleRoomPriceChange = (room: Room, price: number) => {
		const updatedRoom = { ...room, price };
		handleRoomUpdated(updatedRoom);
	};

	const handleStepClick = (step: CreateListingStep, subStep?: CreateListingSubStep) => {
		const firstIncompleteStep = steps.find(item => stepStatuses[item] === StepStatus.Incomplete);
		if (stepStatuses[step] !== StepStatus.Complete && step !== firstIncompleteStep) {
			return;
		}

		if (!subStep) {
			const subSteps = addDigsService.getSubSteps(step);
			if (subSteps) {
				const defaultSubStep = subSteps[0];
				setCurrentStep({
					step,
					subStep: defaultSubStep,
				});
				return;
			}
		}

		setCurrentStep({
			step,
			subStep,
		});
	};

	const handleCancelClick = () => {
		history.push(routes.manage_digs);
	};

	const handleBackClick = async () => {
		if (isLoading) {
			return;
		}

		if (currentStep.subStep) {
			const subStep = currentStep.subStep as CreateListingSubStep;
			const currentSubSteps = addDigsService.getSubSteps(currentStep.step) || [];
			const currentSubStepIndex = currentSubSteps.indexOf(subStep);
			const isFirstSubStep = !currentSubStepIndex;

			if (!isFirstSubStep) {
				setCurrentStep(prev => ({
					...prev,
					subStep: currentSubSteps[currentSubStepIndex - 1],
				}));
				return;
			}
		}

		const currentStepIndex = steps.indexOf(currentStep.step);
		if (!currentStepIndex) {
			handleCancelClick();
			return;
		}

		const prevStep = steps[currentStepIndex - 1];
		const prevStepSubSteps = addDigsService.getSubSteps(prevStep);

		setCurrentStep({
			step: prevStep,
			subStep: prevStepSubSteps && prevStepSubSteps[prevStepSubSteps.length - 1],
		});
		scrollToTop();
	};

	const handleNextClick = async () => {
		const state: CreateListingState = {
			...savedState,
			termsAccepted,
			propertyType,
			leaseType,
			singleRoomCount,
			sharedRoomCount,
			roomTypeCount,
			bedroomCount,
			sharedBathrooms,
			tenantsPerSharedBathroom,
			m2,
			furnished,
			availabilityDate,
			flexibleMoveInDate,
			leasePeriodLength,
			flexibleLeasePeriod,
			currentOccupants,
			availablePlaces,
			totalOccupants,
			currentTenantGender,
			preferredNewTenantGender,
			rooms: (property && property.rooms) || [],
			title,
			description,
			price,
			rentalPeriod,
		};

		if (isLoading) {
			return;
		}

		if (currentStep.subStep) {
			const subStep = currentStep.subStep as CreateListingSubStep;
			const validationResult = addDigsService.validateSubStep(subStep, state);

			if (!validationResult.valid) {
				notifyError(validationResult.error || 'Step data invalid', 5000);
				return;
			}
		} else {
			const validationResult = addDigsService.validateStep(currentStep.step, state);

			if (!validationResult.valid) {
				notifyError(validationResult.error || 'Step data invalid', 5000);
				return;
			}
		}
		const currentStepIndex = steps.indexOf(currentStep.step);

		if (currentStep.step === CreateListingStep.HowTo && !property) {
			setIsLoading(true);
			const response = await api.property.create();

			const nextStep = steps[currentStepIndex + 1];
			const nextStepSubSteps = addDigsService.getSubSteps(nextStep);

			setIsLoading(false);

			if (response.status !== 201) {
				notifyError('Something went wrong, please try again later');
				return;
			}

			const featuresResponse = await api.property.getPropertyFeatures(response.data.uuid || '');
			setPropertyFeatures(featuresResponse.data);

			window.history.pushState(
				'state',
				'title',
				routes.create_listing_edit.replace(':id?', response.data.uuid || ''),
			);

			setCurrentStep({
				step: nextStep,
				subStep: nextStepSubSteps && nextStepSubSteps[0],
			});

			setProperty(response.data);
			scrollToTop();
			return;
		}

		if (
			currentStep.step === CreateListingStep.Pictures &&
			currentStep.subStep === CreateListingSubStep.PicturesUpload
		) {
			setIsLoading(true);
			if (property) {
				for (let photo of property.photos) {
					if (!photo.tag) {
						continue;
					}
					const response = await api.property.updatePhoto(property?.uuid || '', photo.uuid, photo);
					if (response.status !== 200) {
						notifyError('Something went wrong, please try again later');
						setIsLoading(false);
						return;
					}
				}
			}
			setIsLoading(false);
		}

		if (!property) {
			return;
		}

		if (
			currentStep.step === CreateListingStep.Pricing &&
			property.lease_type === LeaseType.RoomByRoom
		) {
			setIsLoading(true);
			await Promise.all(
				state.rooms.map(room => api.property.updateRoom(property?.uuid || '', room.uuid, room)),
			);
		}

		if (currentStepIndex === steps.length - 1) {
			const onFinalStepComplete = async () => {
				setIsLoading(true);

				await api.property.complete(property.uuid || '');

				const response = await api.property.update({
					...property,
					is_searchable: true,
				});

				if (response.status === 400) {
					analyticsService.trackEvent(EventName.CreateListingEvent, {
						create_listing_step: CreateListingStepEvent.Success,
					});
					setShowSuccess(true);
					setModerated(false);
					setIsLoading(false);
					return;
				}

				if (response.status === 200) {
					analyticsService.trackEvent(EventName.CreateListingEvent, {
						create_listing_step: CreateListingStepEvent.Success,
					});
					setShowSuccess(true);
					setModerated(true);
					setIsLoading(false);
					setProperty(response.data);
					return;
				}

				notifyError('Something went wrong saving property, please try again later');
				setIsLoading(false);
			};

			if (!userProfileService.isComplete(userContainer.state.v2)) {
				modalContainer.set(ModalType.ProfileSetupInstant, {
					onComplete: () => {
						onFinalStepComplete();
					},
				});
				return;
			}

			scrollToTop();
			onFinalStepComplete();
			return;
		}

		setIsLoading(true);
		const response = await api.property.update({
			...property,
			sub_type: propertyType || null,
			lease_type: leaseType || null,
			available_private_bedrooms: singleRoomCount,
			available_shared_bedrooms: sharedRoomCount,
			available_room_types: roomTypeCount,
			total_bedrooms: bedroomCount,
			rooms: property ? property.rooms : [],
			total_shared_bathrooms: sharedBathrooms,
			tenants_per_shared_bathroom: tenantsPerSharedBathroom,
			furnished: furnished || null,
			availability_date: availabilityDate || null,
			move_in_date_flexible: flexibleMoveInDate,
			lease_term: leasePeriodLength,
			lease_term_flexible: flexibleLeasePeriod,
			property_size: m2,
			current_occupants: currentOccupants || 0,
			total_places_available_value: availablePlaces || 0,
			total_occupants: totalOccupants || 0,
			current_genders: currentTenantGender,
			preferred_genders: preferredNewTenantGender,
			tenant_preferences: [],
			// This field is saved on selection of new address
			location_data: undefined,
			videos: videoLink ? [{ url: videoLink }] : [],
			title: title,
			description: description,
			billing_cycle: rentalPeriod,
			price: price,
			complete: currentStepIndex === steps.length - 2 || property.complete,
		});

		const nextStep = steps[currentStepIndex + 1];
		const nextStepSubSteps = addDigsService.getSubSteps(nextStep);

		if (response.status !== 200) {
			setIsLoading(false);
			scrollToTop();
			notifyError('Something went wrong, please try again later');
			return;
		}

		const featuresResponse = await api.property.updatePropertyFeatures(
			(property && property.uuid) || '',
			propertyFeatures,
		);

		if (featuresResponse.status !== 200) {
			setIsLoading(false);
			scrollToTop();
			notifyError('Failed while saving property features');
			return;
		}

		setIsLoading(false);
		setProperty(response.data);
		setPropertyFeatures(featuresResponse.data);

		if (currentStep.subStep === CreateListingSubStep.PicturesVideo && videoLink) {
			setIsLoading(true);
			await api.property.uploadVideo((property && property.uuid) || '', videoLink);
			setIsLoading(false);
		}

		if (currentStep.subStep) {
			const subStep = currentStep.subStep as CreateListingSubStep;
			const currentSubSteps = addDigsService.getSubSteps(currentStep.step) || [];

			const isLastSubStep = subStep === currentSubSteps[currentSubSteps.length - 1];
			if (!isLastSubStep) {
				setCurrentStep(prev => ({
					...prev,
					subStep: currentSubSteps[currentSubSteps.indexOf(subStep) + 1],
				}));
				return;
			}

			if (currentSubSteps.some(subStep => !addDigsService.validateSubStep(subStep, state).valid)) {
				notifyError('All sub-steps must be valid before proceeding to the next step');
				return;
			}
		}

		setCurrentStep({
			step: nextStep,
			subStep: nextStepSubSteps && nextStepSubSteps[0],
		});
		scrollToTop();
	};

	const getPrimaryActionButtonText = (step: CreateListingStep) => {
		if (step === CreateListingStep.HowTo) {
			return 'Get started';
		}
		if (step === steps[steps.length - 1]) {
			return 'Post my listing';
		}
		return 'Continue';
	};

	if (showSuccess && property) {
		return (
			<PageContainer>
				<Helmet>
					<title>{meta.title}</title>
					<meta name="description" content={meta.desc} />
				</Helmet>
				<HeaderContainer>
					<Header searchable />
				</HeaderContainer>
				<Success property={property} moderated={moderated} />
			</PageContainer>
		);
	}

	return (
		<PageContainer>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.desc} />
			</Helmet>
			<HeaderContainer>
				<Header searchable />
			</HeaderContainer>
			<PanelsContainer>
				<LeftPanel
					steps={steps.map(step => ({
						step,
						subSteps: (addDigsService.getSubSteps(step) || []).map(subStep => ({
							subStep,
							displayText: subStepDisplayText[subStep],
							status: subStepStatuses[subStep],
						})),
						displayText: stepDisplayText[step],
						status: stepStatuses[step] || StepStatus.Incomplete,
					}))}
					currentStep={currentStep.step}
					currentSubStep={currentStep.subStep}
					onStepSelect={handleStepClick}
					onCancelClick={handleCancelClick}
				/>
				<CenterPanel ref={scrollTopPanelRef}>
					<DesktopOnly>
						<CenterPanelTopBar />
						<ProgressBar percentage={progressPercentage} />
					</DesktopOnly>

					<MobileOnly>
						<MobileHeader>
							<CenterPanelTopBar>
								{currentStep.step === CreateListingStep.HowTo ? (
									<Cross onClick={handleCancelClick} style={{ marginRight: 8 }} />
								) : (
									<SaveAndExitText onClick={handleCancelClick}>Save and exit</SaveAndExitText>
								)}
								<Row>
									{featureFlagContainer.isEnabled(FeatureFlag.ManageListingEnhancements) ? (
										<InfoButton onClick={() => modalContainer.set(ModalType.ContactUs)}>
											<PhoneSvg />
											Need help?
										</InfoButton>
									) : (
										<QuestionIcon onClick={() => setShowContactUsModal(true)} />
									)}
									{stepInfoContent && (
										<InfoButton onClick={() => setShowStepInfoModal(true)}>
											<InfoIcon />
											{featureFlagContainer.isEnabled(FeatureFlag.ManageListingEnhancements) ? (
												<>Tips</>
											) : (
												<>{stepInfoContent.title}</>
											)}
										</InfoButton>
									)}
								</Row>
							</CenterPanelTopBar>
							<ProgressBar percentage={progressPercentage} />
						</MobileHeader>
						<MobileHeaderSpacing />
					</MobileOnly>

					<CenterPanelContentContainer ref={scrollTopContainerRef}>
						<CenterPanelContent>
							{currentStep.step === CreateListingStep.HowTo && (
								<HowTo
									termsAccepted={termsAccepted}
									onTermsCheckboxClick={handleTermsCheckboxClick}
								/>
							)}
							{currentStep.step === CreateListingStep.YourListing && (
								<>
									{currentStep.subStep === CreateListingSubStep.YourListingPropertyType && (
										<PropertyType type={propertyType} onTypeSelect={handlePropertyTypeChange} />
									)}
									{currentStep.subStep === CreateListingSubStep.YourListingAvailability && (
										<Availability
											leaseType={leaseType}
											propertyType={propertyType || PropertyTypeEnum.Unknown}
											onLeaseTypeChange={setLeaseType}
											singleRoomCount={singleRoomCount}
											sharedRoomCount={sharedRoomCount}
											roomTypeCount={roomTypeCount}
											bedroomCount={bedroomCount}
											isLocked={
												addDigsService.validateSubStep(currentStep.subStep, savedState).valid
											}
											onSingleRoomCountChange={setSingleRoomCount}
											onSharedRoomCountChange={setSharedRoomCount}
											onBedroomCountChange={setBedroomCount}
											onRoomTypeCountChange={setRoomTypeCount}
										/>
									)}
									{currentStep.subStep === CreateListingSubStep.YourListingRooms && (
										<Rooms
											rooms={property?.rooms || []}
											leaseType={savedState.leaseType}
											propertyType={savedState.propertyType || PropertyTypeEnum.Unknown}
											onRoomSelect={handleRoomSelect}
										/>
									)}
									{currentStep.subStep === CreateListingSubStep.YourListingDetails && (
										<ListingDetails
											sharedBathrooms={sharedBathrooms}
											tenantsPerSharedBathroom={tenantsPerSharedBathroom}
											m2={m2 || undefined}
											furnished={furnished}
											availabilityDate={availabilityDate || undefined}
											flexibleMoveInDate={flexibleMoveInDate}
											leasePeriodLength={leasePeriodLength}
											flexibleLeasePeriod={flexibleLeasePeriod}
											features={propertyFeatures}
											leaseType={savedState.leaseType}
											onSharedBathroomsChange={setSharedBathrooms}
											onTenantsPerSharedBathroomChange={setTenantsPerSharedBathroom}
											onM2Change={setM2}
											onFurnishedChange={setFurnished}
											onAvailabilityDateChange={setAvailabilityDate}
											onFlexibleMoveInDateChange={setFlexibleMoveInDate}
											onLeasePeriodLengthChange={setLeasePeriodLength}
											onFlexibleLeasePeriodChange={setFlexibleLeasePeriod}
											onFeatureToggle={handleFeatureToggle}
										/>
									)}
								</>
							)}
							{currentStep.step === CreateListingStep.Features && (
								<Features features={propertyFeatures} onFeaturesChange={setPropertyFeatures} />
							)}
							{currentStep.step === CreateListingStep.Occupants && (
								<>
									{currentStep.subStep === CreateListingSubStep.OccupantsCapacity && (
										<Occupants
											currentOccupants={currentOccupants}
											availablePlaces={availablePlaces}
											totalOccupants={totalOccupants}
											currentTenantGender={currentTenantGender}
											preferredNewTenantGender={preferredNewTenantGender}
											onCurrentOccupantsChange={setCurrentOccupants}
											onAvailablePlacesChange={setAvailablePlaces}
											onTotalOccupantsChange={setTotalOccupants}
											onCurrentTenantGenderChange={setCurrentTenantGender}
											onPreferredNewTenantGenderChange={setPreferredNewTenantGender}
										/>
									)}
									{currentStep.subStep === CreateListingSubStep.OccupantsRules && (
										<Rules features={propertyFeatures} onFeaturesChange={setPropertyFeatures} />
									)}
								</>
							)}
							{currentStep.step === CreateListingStep.Location && (
								<>
									{currentStep.subStep === CreateListingSubStep.LocationAtmosphere && (
										<Atmosphere
											features={propertyFeatures}
											onFeaturesChange={setPropertyFeatures}
										/>
									)}
									{currentStep.subStep === CreateListingSubStep.LocationAddress && (
										<Address address={property?.location} onAddressChange={handleAddressChange} />
									)}
								</>
							)}
							{currentStep.step === CreateListingStep.Pictures && (
								<>
									{currentStep.subStep === CreateListingSubStep.PicturesUpload && (
										<PictureUpload
											pictures={property?.photos || []}
											tags={photoTags}
											onUpload={handlePhotoUpload}
											onUploadMultiple={handleMultiplePhotoUpload}
											onPhotoUpdate={handlePhotoUpdate}
											onDelete={handlePhotoDelete}
											onReorder={handlePhotosReorder}
										/>
									)}
									{currentStep.subStep === CreateListingSubStep.PicturesVideo && (
										<Video link={videoLink} onLinkChange={setVideoLink} />
									)}
								</>
							)}
							{currentStep.step === CreateListingStep.NameListing && (
								<NameListing
									title={title}
									description={description}
									onTitleChange={setTitle}
									onDescriptionChange={setDescription}
								/>
							)}
							{currentStep.step === CreateListingStep.Pricing && (
								<Pricing
									propertyType={propertyType || PropertyTypeEnum.Unknown}
									leaseType={leaseType}
									rooms={property?.rooms || []}
									features={propertyFeatures}
									onFeaturesChange={setPropertyFeatures}
									onRoomPriceChange={handleRoomPriceChange}
									sharedRooms={sharedRoomCount}
									singleRooms={singleRoomCount}
									totalRooms={bedroomCount}
									rentalPeriod={rentalPeriod}
									price={price}
									onRentalPeriodChange={setRentalPeriod}
									onPriceChange={setPrice}
									currency={property?.currency || defaultCurrency}
								/>
							)}
							{currentStep.step === CreateListingStep.Preview && property && (
								<Preview
									property={property}
									features={propertyFeatures}
									onStepNavigate={handleStepClick}
								/>
							)}
						</CenterPanelContent>
					</CenterPanelContentContainer>
					<ActionBar singleItem={currentStep.step == CreateListingStep.HowTo}>
						{currentStep.step !== CreateListingStep.HowTo && (
							<BackButton onClick={handleBackClick}>Back</BackButton>
						)}
						<Button
							isDisabled={!!!termsAccepted}
							noMargin
							isLoading={isLoading}
							maxWidth={200}
							onClick={handleNextClick}
						>
							{getPrimaryActionButtonText(currentStep.step)}
						</Button>
					</ActionBar>
				</CenterPanel>
				<RightPanel content={stepInfoContent} />
			</PanelsContainer>
			{selectedRoom && (
				<RoomModal
					propertyId={property?.uuid || ''}
					leaseType={savedState.leaseType}
					room={selectedRoom}
					propertyType={propertyType || PropertyTypeEnum.Unknown}
					onRoomNameChange={value => setSelectedRoom(room => room && { ...room, title: value })}
					onTenantCountChange={value => setSelectedRoom(room => room && { ...room, guests: value })}
					onTotalAvailableChange={value =>
						setSelectedRoom(room => room && { ...room, total_available: value })
					}
					onFurnishedTypeChange={value =>
						setSelectedRoom(room => room && { ...room, furnished: value })
					}
					onBathroomTypeChange={value =>
						setSelectedRoom(room => room && { ...room, bathroom_type: value })
					}
					onPhotosUpdated={handleRoomPhotosUpdate}
					onAvailabilityDateChange={value =>
						setSelectedRoom(room => room && { ...room, availability_date: value })
					}
					onClose={() => setSelectedRoom(undefined)}
					onRoomUpdated={handleRoomUpdated}
				/>
			)}
			{showStepInfoModal && (
				<StepInfoModal content={stepInfoContent} onClose={() => setShowStepInfoModal(false)} />
			)}
			{showContactUsModal && <ContactUsModal onClose={() => setShowContactUsModal(false)} />}
		</PageContainer>
	);
};

export default withAuth(AddDigs);
