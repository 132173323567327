import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';
import { ReactComponent as RadioUnselected } from 'assets/images/icons/outline.svg';
import { ReactComponent as RadioSelected } from 'assets/images/icons/selected.svg';

import Text from './Text';
import { LeaseType, PropertyType, RentalPeriod } from './types';
import FieldHeader from './FieldHeader';
import Divider from 'lib/Divider';
import InfoText from 'lib/InfoText';
import Input from 'lib/Input';
import CheckboxRow from 'lib/CheckboxRow';
import { Feature, FeatureCategory, FeatureMetaDescription, Room, RoomType } from 'models/property';
import FieldSubHeader from './FieldSubHeader';
import { Select } from 'lib/Select';
import numberFormatService from 'services/numberFormatService';
import { furnishedOptions } from './furnishedOptions';
import { Currency } from 'models/listing';
import { string } from 'prop-types';

const { colors } = theme;

interface PricingProps {
	propertyType: PropertyType;
	leaseType: LeaseType;
	rooms: Room[];
	features: Feature[];
	sharedRooms: number;
	singleRooms: number;
	totalRooms: number;
	price: number;
	rentalPeriod: RentalPeriod;
	currency: Currency;
	onFeaturesChange(features: Feature[]): void;
	onRoomPriceChange(room: Room, price: number): void;
	onRentalPeriodChange(period: RentalPeriod): void;
	onPriceChange(price: number): void;
}

const radioOptions = [
	{
		type: RentalPeriod.Monthly,
		label: 'Monthly',
	},
	{
		type: RentalPeriod.Weekly,
		label: 'Weekly',
	},
];

const Pricing = ({
	propertyType,
	leaseType,
	rooms,
	features,
	onFeaturesChange,
	onRoomPriceChange,
	sharedRooms,
	singleRooms,
	totalRooms,
	rentalPeriod,
	price,
	currency,
	onRentalPeriodChange,
	onPriceChange,
}: PricingProps) => {
	const shouldShowField = (field: FeatureMetaDescription, feature: Feature): boolean => {
		if (field.show_if) {
			const dependeeField = feature.meta_description.find(
				item => item.display === field.show_if?.display,
			);
			if (!dependeeField) {
				return false;
			}
			return feature.meta[dependeeField.input_type] === field.show_if.value;
		}

		return true;
	};

	const handlePriceChange = (value: string) => {
		const price = value.replace(/\D/g, '');
		onPriceChange(+price);
	};

	const handleRoomPriceChange = (room: Room, value: string) => {
		const room_price = value.replace(/\D/g, '');
		onRoomPriceChange(room, +room_price);
	};

	const handleFeatureToggle = (featureId: number) => {
		onFeaturesChange(
			features.map(f => ({ ...f, active: f.id === featureId ? !f.active : f.active })),
		);
	};

	const handleFeatureMetaUpdate = (featureId: number, inputType: string, value: any) => {
		onFeaturesChange(
			features.map(f => ({
				...f,
				meta:
					f.id === featureId
						? {
								...f.meta,
								[inputType]: value,
						  }
						: f.meta,
			})),
		);
	};
	return (
		<>
			<Title standardMargin>Lastly, let’s set your price</Title>
			<SubHeader>Price can have a huge impact on the amount of enquiries you recieve.</SubHeader>
			<Container>
				<FieldHeader>How often is rental due?</FieldHeader>
				<Row>
					{radioOptions.map(option => (
						<RadioOption key={option.type} onClick={() => onRentalPeriodChange(option.type)}>
							<RadioToggleContainer>
								{rentalPeriod === option.type ? <RadioSelected /> : <RadioUnselected />}
							</RadioToggleContainer>
							<RadioOptionLabel>{option.label}</RadioOptionLabel>
						</RadioOption>
					))}
				</Row>
				<Divider />
				{leaseType === LeaseType.EntirePlace && (
					<>
						<Text>
							<b>Entire place</b> rental amount
						</Text>
						<InfoText>
							{singleRooms} single room{singleRooms === 1 ? '' : 's'} • {sharedRooms} shared room
							{sharedRooms === 1 ? '' : 's'} • {totalRooms} bedroom {propertyType}
						</InfoText>
						<Input
							placeholder={'0'}
							value={price > 0 ? price : ''}
							type={'text'}
							fontSize={16}
							prefix={currency.currency_symbol}
							onChange={val => {
								handlePriceChange(val as string);
							}}
							suffix={rentalPeriod === RentalPeriod.Monthly ? 'Monthly' : 'Weekly'}
						/>
						<Divider />
					</>
				)}
				{leaseType === LeaseType.RoomByRoom && (
					<>
						{rooms.map(room => (
							<div key={room.uuid}>
								<FieldSubHeaderSmallMargin>
									{room.title}{' '}
									<NormalFontWeight>
										rental amount {room.bedroom_type === RoomType.Shared && 'per person'}
									</NormalFontWeight>
								</FieldSubHeaderSmallMargin>
								<InfoText>
									{room.bedroom_type === RoomType.Private && `private room  • `}
									{room.bedroom_type === RoomType.Shared && `shared room  • `}
									{furnishedOptions.find(o => o.value === room.furnished)?.label.toLowerCase()}
								</InfoText>
								<Input
									placeholder={'0'}
									value={room.price > 0 ? room.price : ''}
									type={'text'}
									fontSize={16}
									prefix={currency.currency_symbol}
									onChange={val => {
										handleRoomPriceChange(room, val as string);
									}}
									suffix={rentalPeriod === RentalPeriod.Monthly ? 'Monthly' : 'Weekly'}
								/>
							</div>
						))}
						{propertyType !== PropertyType.StudentResidence && (
							<PriceTotal>
								<span>Total value</span>
								<span>
									{numberFormatService.formatCurrency(
										rooms.reduce((price, room) => price + room.price * (room.guests || 1), 0),
										currency.currency_symbol,
									)}
									{rentalPeriod === RentalPeriod.Monthly ? ' pm' : ' pw'}
								</span>
							</PriceTotal>
						)}
						<Divider />
					</>
				)}
				<FieldHeader>Additional fees</FieldHeader>
				{features
					.filter(({ category }) => category === FeatureCategory.LeaseConditions)
					.sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					})
					.map(item => (
						<div key={item.id}>
							<CheckboxRow
								text={item.name}
								checked={item.active}
								onClick={() => handleFeatureToggle(item.id)}
							/>
							{item.active &&
								item.meta_description
									.filter(
										meta =>
											meta.valid &&
											(meta.input_type === 'select' || meta.input_type === 'string') &&
											shouldShowField(meta, item),
									)
									.map(meta => (
										<div key={meta.display}>
											<FieldSubHeader>{meta.display}</FieldSubHeader>
											{meta.input_type === 'select' && (
												<Select
													placeholder={meta.prompt || 'Select'}
													options={meta.options.map(option => ({ label: option, value: option }))}
													selectedOption={
														item.meta.select && { label: item.meta.select, value: item.meta.select }
													}
													onChange={option =>
														handleFeatureMetaUpdate(item.id, meta.input_type, option.value)
													}
												/>
											)}
											{meta.input_type === 'string' && (
												<Input
													placeholder={meta.prompt || ''}
													value={item.meta.string}
													onChange={val => handleFeatureMetaUpdate(item.id, meta.input_type, val)}
												/>
											)}
										</div>
									))}
						</div>
					))}
			</Container>
		</>
	);
};

export default Pricing;

const Container = styled.div`
	padding-bottom: 32px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
	margin-bottom: 24px;
`;

const Row = styled.div`
	display: flex;
`;

const RadioOption = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	padding-right: 16px;
`;

const RadioOptionLabel = styled(Text)`
	color: ${colors.grey60};
	margin: 0;
	margin-right: 24px;
`;

const RadioToggleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 32px;
	min-height: 32px;
	margin-right: 16px;
`;

const PriceTotal = styled(FieldHeader)`
	color: ${colors.pink};
	display: flex;
	justify-content: space-between;
	margin-top: 28px;
`;

const NormalFontWeight = styled.span`
	font-weight: 400;
`;

const FieldSubHeaderSmallMargin = styled(FieldSubHeader)`
	margin-bottom: 4px;
`;
