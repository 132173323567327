import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import SubTitle from 'lib/SubTitle';
import InfoText from 'lib/InfoText';

import { StepInfoContent as StepInfoContentData } from './types';

const { colors } = theme;

const ContentTitle = styled(SubTitle)`
	font-weight: 600;
	font-size: 20px;
`;

const ContentHeader = styled(InfoText)`
	font-weight: 600;
	color: ${colors.darkBlue};
	font-size: 16px;
	margin-bottom: 8px;
`;

const ContentText = styled(InfoText)`
	font-size: 16px;
	color: ${colors.darkBlue};
`;

const ListItem = styled.li`
	list-style: unset;
	margin-left: 16px;
	margin-bottom: 8px;
`;

interface StepInfoContentProps {
	content: StepInfoContentData;
}

const StepInfoContent = ({ content }: StepInfoContentProps) => (
	<>
		{content.title && <ContentTitle>{content.title}</ContentTitle>}
		{content.items.map((item, i) => (
			<div key={i}>
				{item.header && <ContentHeader>{item.header}</ContentHeader>}
				<ContentText>{item.body}</ContentText>
				{item.numberedList && item.numberedList.length && (
					<ContentText>
						<ol>
							{item.numberedList.map(line => (
								<ListItem key={line}>{line}</ListItem>
							))}
						</ol>
					</ContentText>
				)}
			</div>
		))}
	</>
);

export default StepInfoContent;
