import React from 'react';

import styled from 'styled-components';

import { BREAKPOINT_TABLET_PX, BREAKPOINT_DESKTOP_LARGE_PX } from 'constants/breakPoints';
import theme from 'config/theme';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked-green.svg';
import { ReactComponent as LockIcon } from 'assets/images/icons/lock.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';
import { Title } from 'lib/Title';
import { Button } from 'lib/Button';
import SubTitle from 'lib/SubTitle';
import { CreateListingStep, CreateListingSubStep, StepStatus } from './types';

const { colors } = theme;

const StyledLeftPanel = styled.div`
	display: none;
	background: ${colors.gray};
	padding: 32px 0;
	padding-bottom: 0;
	min-width: 305px;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		display: flex;
		flex-direction: column;
		width: 350px;
	}

	@media (min-width: ${BREAKPOINT_DESKTOP_LARGE_PX}px) {
		width: 450px;
	}
`;

const LeftPanelScrollableContent = styled.div`
	width: 100%;
	flex: 1;
	overflow-y: auto;
`;

const LeftPanelContent = styled.div`
	padding: 0 32px;
	width: 100%;
`;

const LeftPanelStepContainer = styled.div<{ isSelected?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	border-radius: 16px;
	background: ${({ isSelected }) => (isSelected ? colors.shadedGray : 'transparent')};
	margin: 0 -16px;
	padding: 0 16px;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
`;

const LeftPanelStepText = styled.div<{ isSelected?: boolean; isDisabled?: boolean }>`
	font-weight: ${({ isSelected }) => (isSelected ? '600' : 'normal')};
	font-size: 16px;
	opacity: ${({ isSelected }) => (isSelected ? '1' : '0.8')};
	color: ${({ isDisabled }) => (isDisabled ? colors.grey60 : colors.darkBlue)};
`;

const LeftPanelSubStepContainer = styled.div`
	margin-bottom: 24px;
	cursor: pointer;
`;

interface LeftPanelCheckboxContainerProps {
	noBorder?: boolean;
	noFade?: boolean;
	greyBorder?: boolean;
}

const LeftPanelCheckboxContainer = styled.div<LeftPanelCheckboxContainerProps>`
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	border-radius: 24px;
	align-items: center;
	border: ${({ noBorder, greyBorder }) =>
		noBorder ? 'none' : `2px solid ${greyBorder ? colors.darkBlue : colors.darkTurquoise}`};
	opacity: ${({ noFade }) => (noFade ? '1' : '0.5')};
`;

const ActionBar = styled.div<{ greyBackground?: boolean }>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 8px 16px;
	border-top: 1px solid ${colors.gray};
	background: ${({ greyBackground }) => (greyBackground ? colors.gray : 'transparent')};

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		padding: 16px 24px;
	}
`;

const SubStepsContainer = styled.div`
	margin-top: 24px;
	margin-left: 16px;
`;

interface LeftPanelProps {
	steps: {
		step: CreateListingStep;
		subSteps: {
			subStep: CreateListingSubStep;
			status: StepStatus;
			displayText: string;
		}[];
		status: StepStatus;
		displayText: string;
	}[];
	currentStep: CreateListingStep;
	currentSubStep?: CreateListingSubStep;
	onStepSelect(step: CreateListingStep, subStep?: CreateListingSubStep): void;
	onCancelClick(): void;
}

const LeftPanel = ({
	steps,
	currentStep,
	currentSubStep,
	onStepSelect,
	onCancelClick,
}: LeftPanelProps) => {
	const firstIncompleteStepInfo = steps.find(step => step.status !== StepStatus.Complete);
	const firstIncompleteStep = firstIncompleteStepInfo ? firstIncompleteStepInfo.step : null;

	return (
		<StyledLeftPanel>
			<LeftPanelScrollableContent>
				<LeftPanelContent>
					<SubTitle>Create a listing</SubTitle>
					<Title>Your Progress:</Title>
					{steps.map(stepInfo => {
						return (
							<div key={stepInfo.step}>
								<LeftPanelStepContainer
									isSelected={currentStep === stepInfo.step}
									onClick={() => onStepSelect(stepInfo.step)}
								>
									<LeftPanelStepText isSelected={currentStep === stepInfo.step}>
										{stepInfo.displayText}
									</LeftPanelStepText>
									<LeftPanelCheckboxContainer
										noBorder={stepInfo.status === StepStatus.Complete}
										noFade={
											stepInfo.status === StepStatus.Complete ||
											stepInfo.step === currentStep ||
											stepInfo.step === firstIncompleteStep
										}
										greyBorder={
											stepInfo.status === StepStatus.Incomplete &&
											stepInfo.step !== firstIncompleteStep
										}
									>
										{stepInfo.status === StepStatus.Complete && (
											<CheckboxCheckedIcon style={{ height: '100%', width: '100%' }} />
										)}
										{stepInfo.status === StepStatus.Incomplete &&
											stepInfo.step === firstIncompleteStep && (
												<PlusIcon
													style={{
														height: '16px',
														width: '16px',
														fill: colors.darkTurquoise,
													}}
												/>
											)}
										{stepInfo.status === StepStatus.Incomplete &&
											stepInfo.step !== firstIncompleteStep && (
												<LockIcon
													style={{
														height: '14px',
														width: '14px',
														fill: colors.darkBlue,
													}}
												/>
											)}
									</LeftPanelCheckboxContainer>
								</LeftPanelStepContainer>
								{stepInfo.step === currentStep &&
									stepInfo.subSteps &&
									Boolean(stepInfo.subSteps.length) && (
										<SubStepsContainer>
											{stepInfo.subSteps.map(subStepInfo => (
												<LeftPanelSubStepContainer
													key={subStepInfo.subStep}
													onClick={() => {
														if (subStepInfo.status === StepStatus.Incomplete) {
															return;
														}
														onStepSelect(stepInfo.step, subStepInfo.subStep);
													}}
												>
													<LeftPanelStepText
														isDisabled={
															subStepInfo.status === StepStatus.Incomplete &&
															subStepInfo.subStep !== currentSubStep
														}
														isSelected={subStepInfo.subStep === currentSubStep}
													>
														{subStepInfo.displayText}
													</LeftPanelStepText>
												</LeftPanelSubStepContainer>
											))}
										</SubStepsContainer>
									)}
							</div>
						);
					})}
				</LeftPanelContent>
			</LeftPanelScrollableContent>
			<ActionBar>
				<Button isFullWidth isOutline onClick={onCancelClick}>
					{currentStep === CreateListingStep.HowTo ? 'Cancel' : 'Save and exit'}
				</Button>
			</ActionBar>
		</StyledLeftPanel>
	);
};

export default LeftPanel;
