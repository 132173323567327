import React from 'react';

import styled from 'styled-components';

import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';
import theme from 'config/theme';
import Divider from 'lib/Divider';

import NumberInput from './NumberInput';
import Input from 'lib/Input';
import { Select } from 'lib/Select';
import { FurnishedType } from 'models/listing';
import { DateInput } from 'lib/DateInput';
import CheckboxRow from 'lib/CheckboxRow';
import { Feature, FeatureCategory } from 'models/property';
import dateService from 'services/dateService';

import { furnishedOptions } from './furnishedOptions';
import FieldHeader from './FieldHeader';
import FieldSubHeader from './FieldSubHeader';
import FieldActionRow from './FieldActionRow';
import { LeaseType } from './types';

const { colors } = theme;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
`;

const SectionHeader = styled(SubTitle)`
	font-weight: 600;
	margin-top: 24px;
	font-size: 22px;
	margin-bottom: 24px;
`;

const Container = styled.div`
	padding-bottom: 32px;
`;

interface ListingDetailsProps {
	sharedBathrooms?: number;
	tenantsPerSharedBathroom?: number;
	m2?: number;
	furnished?: FurnishedType;
	availabilityDate?: string;
	flexibleMoveInDate?: boolean;
	leasePeriodLength?: number;
	flexibleLeasePeriod?: boolean;
	features: Feature[];
	leaseType: LeaseType;
	onSharedBathroomsChange(val: number): void;
	onTenantsPerSharedBathroomChange(val: number): void;
	onM2Change(val: number): void;
	onFurnishedChange(val: FurnishedType): void;
	onAvailabilityDateChange(val: string): void;
	onFlexibleMoveInDateChange(val: boolean): void;
	onLeasePeriodLengthChange(val: number): void;
	onFlexibleLeasePeriodChange(val: boolean): void;
	onFeatureToggle(val: Feature): void;
}

const ListingDetails = ({
	sharedBathrooms,
	tenantsPerSharedBathroom,
	m2,
	furnished,
	availabilityDate,
	flexibleMoveInDate,
	leasePeriodLength,
	flexibleLeasePeriod,
	features,
	leaseType,
	onSharedBathroomsChange,
	onTenantsPerSharedBathroomChange,
	onM2Change,
	onFurnishedChange,
	onAvailabilityDateChange,
	onFlexibleMoveInDateChange,
	onLeasePeriodLengthChange,
	onFlexibleLeasePeriodChange,
	onFeatureToggle,
}: ListingDetailsProps) => {
	const handleDateSelect = (date?: Date) => {
		if (!date) {
			return;
		}
		onAvailabilityDateChange(dateService.formatForServer(date));
	};
	return (
		<Container>
			<Title standardMargin>What is currently available for rental?</Title>
			<SubHeader>Are you leasing out the entire property, or rooms within a property?</SubHeader>
			<SectionHeader>Property details</SectionHeader>
			<FieldHeader>How many shared bathrooms are on the property?</FieldHeader>
			<NumberInput
				value={sharedBathrooms}
				label="Total number of shared bathrooms"
				onChange={onSharedBathroomsChange}
			/>
			<FieldHeader>How many tenants share a shared bathroom?</FieldHeader>
			<NumberInput
				value={tenantsPerSharedBathroom}
				label="Number of tenants per shared bathroom"
				onChange={onTenantsPerSharedBathroomChange}
			/>
			<Divider />
			<FieldHeader>How many m2 is the property? (Optional)</FieldHeader>
			<Input
				placeholder=""
				value={m2}
				onChange={value => onM2Change(+(value || '').toString().trim())}
			/>
			<FieldHeader>Is the living area furnished?</FieldHeader>
			<Select
				placeholder="Select furnished status"
				options={furnishedOptions}
				selectedOption={furnishedOptions.find(o => o.value === furnished)}
				onChange={option => onFurnishedChange(option.value)}
			/>
			{(furnished === FurnishedType.Fully || furnished === FurnishedType.Semi) && (
				<>
					<FieldSubHeader>Select below which furniture is included on the property</FieldSubHeader>
					{features
						.filter(({ category }) => category === FeatureCategory.Furniture)
						.sort((a, b) => {
							if (a.name < b.name) {
								return -1;
							}
							if (a.name > b.name) {
								return 1;
							}
							return 0;
						})
						.map(item => (
							<CheckboxRow
								key={item.id}
								text={item.name}
								checked={item.active}
								onClick={() => onFeatureToggle(item)}
							/>
						))}
					<Divider />
				</>
			)}
			<SectionHeader>Rental terms</SectionHeader>
			{leaseType === LeaseType.EntirePlace && (
				<>
					<FieldHeader>When is this property available from?</FieldHeader>
					<DateInput
						value={availabilityDate}
						placeholder={'Select a date'}
						onChange={handleDateSelect}
					/>
				</>
			)}
			<FieldActionRow
				label="Are you flexible with move-in dates?"
				yesActionEnabled={!!flexibleMoveInDate}
				noActionEnabled={!flexibleMoveInDate}
				onYesClick={() => onFlexibleMoveInDateChange(true)}
				onNoClick={() => onFlexibleMoveInDateChange(false)}
			/>
			<FieldHeader>How many months is the lease period?</FieldHeader>
			<NumberInput
				value={leasePeriodLength}
				label="Preferred lease period (months)"
				onChange={onLeasePeriodLengthChange}
			/>
			<FieldActionRow
				label="Are you flexible with lease periods?"
				yesActionEnabled={!!flexibleLeasePeriod}
				noActionEnabled={!flexibleLeasePeriod}
				onYesClick={() => onFlexibleLeasePeriodChange(true)}
				onNoClick={() => onFlexibleLeasePeriodChange(false)}
			/>
		</Container>
	);
};

export default ListingDetails;
