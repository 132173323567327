import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import api from 'api';
import theme from 'config/theme';
import Input from 'lib/Input';
import { Select, SelectOption } from 'lib/Select';
import { Modal } from 'lib/Modal';
import Button from 'lib/Button';
import { notifyError, notifyFailure } from 'lib/Notifications';
import ProgressBar from 'lib/ProgressBar';
import uploadPlaceholder from 'assets/images/add-digs/upload-placeholder.svg';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { FurnishedType } from 'models/listing';
import { Feature, FeatureCategory, Photo, Room, RoomType } from 'models/property';
import CheckboxRow from 'lib/CheckboxRow';
import dateService from 'services/dateService';
import { DateInput } from 'lib/DateInput';

import NumberInput from './NumberInput';
import FieldHeader from './FieldHeader';
import FieldSubHeader from './FieldSubHeader';
import { furnishedOptions } from './furnishedOptions';
import { BathroomType, LeaseType, PropertyType } from './types';
import addDigsService from './addDigsService';
import DeleteImageButton from './DeleteImageButton';
import { TopBar } from 'lib/TopBar';
import listingService from 'services/listingService';

const { colors } = theme;

const bathroomOptions: SelectOption[] = [
	{
		value: BathroomType.Ensuite,
		label: 'Ensuite bathroom',
	},
	{
		value: BathroomType.Shared,
		label: 'Shared bathroom',
	},
];

enum RoomStep {
	Details,
	Photos,
}

interface RoomModalProps {
	propertyId: string;
	leaseType: LeaseType;
	propertyType: PropertyType;
	room: Room;
	onRoomNameChange(value: string): void;
	onAvailabilityDateChange(val: string): void;
	onTenantCountChange(value: number): void;
	onFurnishedTypeChange(value: string): void;
	onBathroomTypeChange(value: string): void;
	onTotalAvailableChange(value: number): void;
	onPhotosUpdated(photos: Photo[]): void;
	onClose(): void;
	onRoomUpdated(room: Room): void;
}

export const RoomModal = ({
	propertyId,
	leaseType,
	room,
	propertyType,
	onRoomNameChange,
	onAvailabilityDateChange,
	onTenantCountChange,
	onFurnishedTypeChange,
	onBathroomTypeChange,
	onPhotosUpdated,
	onClose,
	onRoomUpdated,
	onTotalAvailableChange,
}: RoomModalProps) => {
	const [step, setStep] = useState(RoomStep.Details);
	const [isLoading, setIsLoading] = useState(false);
	const [features, setFeatures] = useState<Feature[]>([]);
	const [scrollBot, setScrollBot] = useState(false);
	const ref = useRef<HTMLInputElement>(null);
	const scrollBottomPanelRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		api.property.getRoomFeatures(propertyId, room.uuid).then(response => {
			setFeatures(response.data);
		});
	}, [propertyId, room.uuid]);

	useEffect(() => {
		scrollToBottom();
	}, [scrollBot]);

	const isFinalStep = step === RoomStep.Photos;
	const isFirstStep = step === RoomStep.Details;

	const handleDateSelect = (date?: Date) => {
		if (!date) {
			return;
		}
		onAvailabilityDateChange(dateService.formatForServer(date));
	};

	const handleNextClick = async () => {
		if (step === RoomStep.Details) {
			const validationResult = addDigsService.validateRoom(room, leaseType, propertyType, false);

			if (!validationResult.valid) {
				notifyError(validationResult.error || 'Room not valid');
				return;
			}

			setIsLoading(true);
			const response = await api.property.updateRoom(propertyId, room.uuid, {
				...room,
				features: listingService.sanitiseFeatures(room.features),
			});
			if (response.status !== 200) {
				notifyError('Failed to save room');
				setIsLoading(false);
				return;
			}
			onRoomUpdated(room);
			const featuresResponse = await api.property.updateRoomFeatures(
				propertyId,
				room.uuid,
				listingService.sanitiseFeatures(features),
			);
			setIsLoading(false);
			if (featuresResponse.status !== 200) {
				notifyError('Failed to save room features');
				return;
			}
			setStep(RoomStep.Photos);
		}

		if (step === RoomStep.Photos) {
			const validationResult = addDigsService.validateRoom(room, leaseType, propertyType);

			if (!validationResult.valid) {
				notifyError(validationResult.error || 'Room photos not valid');
				return;
			}
			onClose();
		}
	};

	const handleBackClick = () => {
		if (step === RoomStep.Photos) {
			setStep(RoomStep.Details);
		}
		if (step === RoomStep.Details) {
			onClose();
		}
	};

	const handleFeatureToggle = (featureId: number) => {
		setFeatures(prev =>
			prev.map(f => ({ ...f, active: f.id === featureId ? !f.active : f.active })),
		);
	};

	const handleFeatureUpdate = (feature: Feature) => {
		setFeatures(prev => prev.map(f => (f.id === feature.id ? { ...feature } : f)));
	};

	const handleUploadClick = async () => {
		if (ref.current) {
			ref.current.click();
		}
	};

	const handleFileUpload = async (event: any) => {
		if (isLoading) {
			return;
		}

		if (!event.target.files || !event.target.files.length) {
			return;
		}

		const photosData = new Array(event.target.files.length)
			.fill(undefined)
			.map((_, i) => event.target.files[i])
			.map((file: any) => {
				const data = new FormData();
				data.append('photo', file);
				return data;
			});

		setIsLoading(true);

		const responses = await Promise.all(
			photosData.map(
				async (data: any) => await api.property.uploadRoomPhoto(propertyId, room.uuid, data),
			),
		);

		responses.forEach(async response => {
			if (response.status !== 201) {
				notifyFailure('Failed to upload photo');
				return;
			}
		});

		setIsLoading(false);

		const newPhotos = [
			...room.photos,
			...responses.filter(response => response.status === 201).map(response => response.data),
		];
		onPhotosUpdated(newPhotos);
	};

	const handleDeleteImageClick = async (image: Photo) => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);

		const response = await api.property.deleteRoomPhoto(propertyId, room.uuid, image.uuid);

		setIsLoading(false);

		if (response.status !== 204) {
			notifyFailure('Failed to delete photo');
			return;
		}

		const newPhotos = room.photos.filter(img => img.uuid !== image.uuid);
		onPhotosUpdated(newPhotos);
	};

	const calculateProgressPercentage = (currentStep: RoomStep): number => {
		if (currentStep === RoomStep.Details) {
			return 50;
		}
		return 100;
	};

	const renderDeleteImageButton = (image: Photo) => {
		return <DeleteImageButton onClick={() => handleDeleteImageClick(image)} />;
	};

	const scrollToBottom = () => {
		if (scrollBottomPanelRef.current) {
			scrollBottomPanelRef.current.scrollTop = scrollBottomPanelRef.current.scrollHeight;
		}
		setScrollBot(false);
	};

	return (
		<Modal bottomOffset={97} onBackgroundClick={onClose}>
			<TopBar title={room.title} onClose={onClose} />
			<ProgressBar percentage={calculateProgressPercentage(step)} />
			<ModalContent ref={scrollBottomPanelRef}>
				{step === RoomStep.Details && (
					<>
						<FieldHeader>What do you want to name this bedroom?</FieldHeader>
						<Input
							placeholder="Bedroom name eg. Single room"
							value={room.title}
							onChange={onRoomNameChange}
						/>
						{propertyType === PropertyType.StudentResidence && (
							<>
								<FieldHeader>How many rooms of this type are there?</FieldHeader>
								<NumberInput
									value={room.total_available}
									label="No. of rooms of this type"
									onChange={onTotalAvailableChange}
								/>
							</>
						)}
						{leaseType === LeaseType.RoomByRoom && (
							<>
								<FieldHeader>
									{propertyType === PropertyType.StudentResidence
										? 'When are these rooms available from?'
										: 'When is this room available from?'}
								</FieldHeader>
								<DateInput
									value={room.availability_date}
									placeholder={'Select a date'}
									onChange={handleDateSelect}
								/>
							</>
						)}
						{(room.bedroom_type === RoomType.Shared ||
							propertyType === PropertyType.StudentResidence) && (
							<>
								<FieldHeader>
									{propertyType === PropertyType.StudentResidence
										? 'How many tenants can occupy this room type?'
										: 'How many tenants can occupy this room?'}
								</FieldHeader>
								<NumberInput
									value={room.guests}
									label="No. of tenants"
									onChange={onTenantCountChange}
								/>
							</>
						)}
						<FieldHeader>Is this bedroom furnished?</FieldHeader>
						<Select
							placeholder="Select"
							options={furnishedOptions}
							selectedOption={furnishedOptions.find(o => o.value === room.furnished)}
							onChange={option => onFurnishedTypeChange(option.value)}
						/>
						{(room.furnished === FurnishedType.Fully || room.furnished === FurnishedType.Semi) && (
							<>
								<FieldSubHeader>What does the room contain?</FieldSubHeader>
								{features
									.filter(({ category }) => category === FeatureCategory.Amenities)
									.sort((a, b) => {
										if (a.name < b.name) {
											return -1;
										}
										if (a.name > b.name) {
											return 1;
										}
										return 0;
									})
									.map(item => (
										<CheckboxRow
											key={item.id}
											text={item.name}
											checked={item.active}
											onClick={() => handleFeatureToggle(item.id)}
										/>
									))}
								<FieldSubHeader>
									{room.bedroom_type === RoomType.Private && 'Which bed is included'}
									{room.bedroom_type === RoomType.Shared && 'Which beds are included'}
								</FieldSubHeader>
								{features
									.filter(({ category }) => category === FeatureCategory.Beds)
									.map(item =>
										room.bedroom_type === RoomType.Shared ? (
											<MarginBottom key={item.id}>
												<NumberInput
													value={(item.meta && item.meta.number) || 0}
													label={item.name}
													onChange={value =>
														handleFeatureUpdate({
															...item,
															active: Boolean(value > 0),
															meta: {
																number: value,
															},
														})
													}
												/>
											</MarginBottom>
										) : (
											<CheckboxRow
												key={item.id}
												text={item.name}
												checked={item.active}
												onClick={() => handleFeatureToggle(item.id)}
											/>
										),
									)}
							</>
						)}
						<FieldHeader>What type of bathroom does this room have?</FieldHeader>
						<Select
							placeholder="Select"
							options={bathroomOptions}
							selectedOption={bathroomOptions.find(o => o.value === room.bathroom_type)}
							onChange={option => onBathroomTypeChange(option.value)}
							onDropDownClick={() => setScrollBot(true)}
						/>
						{(room.bathroom_type === BathroomType.Ensuite ||
							room.bathroom_type === BathroomType.Shared) && (
							<>
								<FieldSubHeader>
									{room.bathroom_type === BathroomType.Ensuite &&
										'What does the ensuite bathroom contain?'}
									{room.bathroom_type === BathroomType.Shared &&
										'What does the shared bathroom contain?'}
								</FieldSubHeader>
								{features
									.filter(({ category }) => category === FeatureCategory.Bathroom)
									.sort((a, b) => {
										if (a.name < b.name) {
											return -1;
										}
										if (a.name > b.name) {
											return 1;
										}
										return 0;
									})
									.map(item => (
										<CheckboxRow
											key={item.id}
											text={item.name}
											checked={item.active}
											onClick={() => handleFeatureToggle(item.id)}
										/>
									))}
							</>
						)}
					</>
				)}
				{step === RoomStep.Photos && (
					<>
						<UploadContainer>
							<FieldHeader>Upload up to 4 images showing this room</FieldHeader>
							{!room.photos.length && <PlaceHolderImage src={uploadPlaceholder} />}
							{room.photos.length < 4 && (
								<UploadAction onClick={handleUploadClick}>Upload images</UploadAction>
							)}
							<input
								ref={ref}
								type="file"
								onChange={handleFileUpload}
								style={{ display: 'none' }}
								multiple
							/>
							<ImagesContainer>
								{room.photos.length === 1 && (
									<CoverImageContainer>
										{renderDeleteImageButton(room.photos[0])}
										<UploadedImage src={room.photos[0].url} />
									</CoverImageContainer>
								)}
								{room.photos.length > 1 &&
									room.photos.map(image => (
										<ImageContainer key={image.uuid}>
											{renderDeleteImageButton(image)}
											<UploadedImage src={image.url} />
										</ImageContainer>
									))}
							</ImagesContainer>
						</UploadContainer>
					</>
				)}
			</ModalContent>
			<ModalActionBar>
				<Button noMargin isOutline onClick={handleBackClick}>
					{isFirstStep ? 'Cancel' : 'Back'}
				</Button>
				<LeftMarginContainer>
					<Button noMargin isLoading={isLoading} onClick={handleNextClick}>
						{isFinalStep ? 'Done' : 'Next'}
					</Button>
				</LeftMarginContainer>
			</ModalActionBar>
		</Modal>
	);
};

export default RoomModal;

const ModalContent = styled.div`
	padding: 24px 48px;
	overflow-y: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding: 24px;
		height: inherit;
	}
`;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	border-top: 1px solid ${colors.grey10};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px;
	padding-top: 16px;

	@media (max-width: 768px) {
		// position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
		padding: 16px;
	}
`;

const LeftMarginContainer = styled.div`
	margin-left: 24px;
	width: 100%;
`;

const UploadContainer = styled.div`
	text-align: center;
`;

const PlaceHolderImage = styled.img`
	min-width: 120px;
`;

const UploadAction = styled.div`
	color: ${colors.darkTurquoise};
	text-decoration: underline;
	font-weight: 600;
	cursor: pointer;
`;

const ImagesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: auto;
	margin-top: 16px;
	max-width: 400px;
`;

const ImageContainer = styled.div`
	position: relative;
	border-radius: 16px;
	width: calc(50% - 20px);
	margin: 8px 10px;
	height: 110px;
	overflow: hidden;
`;

const CoverImageContainer = styled(ImageContainer)`
	width: 400px;
	height: 240px;
`;

const UploadedImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const MarginBottom = styled.div`
	margin-bottom: 16px;
`;
