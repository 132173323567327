import React from 'react';

import styled from 'styled-components';

import { ReactComponent as RadioUnselected } from 'assets/images/icons/outline.svg';
import { ReactComponent as RadioSelected } from 'assets/images/icons/selected.svg';
import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';
import theme from 'config/theme';

import Text from './Text';
import NumberInput from './NumberInput';
import { LeaseType, PropertyType } from './types';
import FieldHeader from './FieldHeader';
import { notifyError } from 'lib/Notifications';

const { colors } = theme;

const Container = styled.div`
	margin-top: 40px;
	color: ${colors.darkBlue};
	padding-bottom: 32px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
`;

const radioOptions = [
	{
		type: LeaseType.EntirePlace,
		label: 'Entire place',
	},
	{
		type: LeaseType.RoomByRoom,
		label: 'Room by room',
	},
];

interface AvailabilityProps {
	leaseType: LeaseType;
	propertyType: PropertyType;
	singleRoomCount: number;
	sharedRoomCount: number;
	bedroomCount: number;
	roomTypeCount: number;
	isLocked: boolean;
	onLeaseTypeChange(type: LeaseType): void;
	onSingleRoomCountChange(val: number): void;
	onSharedRoomCountChange(val: number): void;
	onBedroomCountChange(val: number): void;
	onRoomTypeCountChange(val: number): void;
}

const Availability = ({
	leaseType,
	propertyType,
	singleRoomCount,
	sharedRoomCount,
	bedroomCount,
	roomTypeCount,
	isLocked,
	onLeaseTypeChange,
	onSingleRoomCountChange,
	onSharedRoomCountChange,
	onBedroomCountChange,
	onRoomTypeCountChange,
}: AvailabilityProps) => {
	const getDescriptionText = () => {
		const baseText = 'You are leasing';
		if (propertyType === PropertyType.StudentResidence) {
			if (roomTypeCount < 1) {
				return `${baseText}...`;
			}

			let text = baseText;

			if (roomTypeCount > 0) {
				text = ` ${text} ${roomTypeCount} room type${+roomTypeCount === 1 ? '' : 's'}`;
			}

			if (bedroomCount > 0) {
				text = ` ${text}, in a ${bedroomCount} bedroom student residence`;
			}

			return text;
		}

		if ((singleRoomCount < 1 && sharedRoomCount < 1 && bedroomCount < 1) || !leaseType) {
			return `${baseText}...`;
		}

		let text = baseText;
		if (singleRoomCount > 0) {
			text += ` ${singleRoomCount} single room${singleRoomCount === 1 ? '' : 's'},`;
		}
		if (sharedRoomCount > 0) {
			text += `${singleRoomCount > 0 ? 'and' : ''} ${sharedRoomCount} shared room${sharedRoomCount === 1 ? '' : 's'},`;
		}
		if (bedroomCount > 0) {
			text += ` in a ${bedroomCount} bedroom ${propertyType},`;
		}

		text = text.slice(0, -1);
		text += ` as ${leaseType === LeaseType.EntirePlace ? 'an entire place lease' : ''}${
			leaseType === LeaseType.RoomByRoom ? 'separate individual leases' : ''
		}${leaseType === LeaseType.Any ? 'either an entire place or separate individual leases' : ''}`;
		return text;
	};

	const executeIfNotLocked = (onChange: () => void) => {
		if (isLocked) {
			notifyError('This step is locked, to edit these fields, create a new listing');
			return;
		}

		onChange();
	};

	return (
		<>
			<Title standardMargin>
				{propertyType === PropertyType.StudentResidence
					? 'How many room types are in this student residence?'
					: 'What is currently available for rental?'}
			</Title>
			{propertyType !== PropertyType.StudentResidence && (
				<SubHeader>Are you leasing out the entire property, or rooms within a property?</SubHeader>
			)}
			<Container>
				{propertyType !== PropertyType.StudentResidence && (
					<>
						<FieldHeader>How many single rooms are available?</FieldHeader>
						<NumberInput
							value={singleRoomCount}
							label="A tenant has their own room for sleeping and privacy"
							onChange={val => executeIfNotLocked(() => onSingleRoomCountChange(val as number))}
						/>
						<FieldHeader>How many shared rooms are available?</FieldHeader>
						<NumberInput
							value={sharedRoomCount}
							label="2 or more tenants in separate beds share this room"
							onChange={val => executeIfNotLocked(() => onSharedRoomCountChange(val as number))}
						/>
						<FieldHeader>How many bedrooms in total are on the property?</FieldHeader>
						<NumberInput
							value={bedroomCount}
							label="Total number of bedrooms"
							onChange={val => executeIfNotLocked(() => onBedroomCountChange(val as number))}
						/>
						<FieldHeader>How will the lease be handled?</FieldHeader>
						<RadioOptions>
							{radioOptions.map(option => (
								<RadioOption
									key={option.type}
									onClick={() => executeIfNotLocked(() => onLeaseTypeChange(option.type))}
								>
									<RadioOptionLabel>{option.label}</RadioOptionLabel>
									<RadioToggleContainer>
										{leaseType === option.type ? <RadioSelected /> : <RadioUnselected />}
									</RadioToggleContainer>
								</RadioOption>
							))}
						</RadioOptions>
					</>
				)}
				{propertyType === PropertyType.StudentResidence && (
					<>
						<FieldHeader>How many room types are there?</FieldHeader>
						<NumberInput
							value={roomTypeCount}
							label="A room which shares the same size and price"
							onChange={val => executeIfNotLocked(() => onRoomTypeCountChange(val as number))}
						/>
						<FieldHeader>How many total rooms are on the property?</FieldHeader>
						<NumberInput
							value={bedroomCount}
							label="Total number of rooms"
							onChange={val => executeIfNotLocked(() => onBedroomCountChange(val as number))}
						/>
					</>
				)}
				<TextBlock>{getDescriptionText()}</TextBlock>
			</Container>
		</>
	);
};

const RadioOptions = styled.div`
	margin-top: 24px;
`;

const RadioOption = styled.div`
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	margin-top: 16px;
	padding-right: 16px;
`;

const RadioOptionLabel = styled(Text)`
	color: ${colors.grey60};
	margin: 0;
`;

const RadioToggleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 32px;
	min-height: 32px;
`;

const TextBlock = styled(Text)`
	color: ${colors.darkTurquoise};
	width: 100%;
	margin: 0;
	text-align: center;
	margin: 16px 0;
	padding: 40px 16px;
	font-weight: 600;
	background: ${colors.darkTurquoiseFaded};
	border-radius: 16px;
`;

export default Availability;
